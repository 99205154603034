/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState, useEffect, useRef} from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc, updateDoc, arrayUnion } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { useLocation, useHistory } from 'react-router-dom';
import { createRandomId } from "RandomId";
import { NavLink } from 'react-router-dom';

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip
} from "reactstrap";
import FontStylePicker from "components/FontStylePicker";

function AddEditClassForm() {

  const location = useLocation();
  const { state } = location;
  const item = state?.item;
  const user = useSelector(selectUser);
  const history = useHistory();
  const classIdForNewClass = useRef(createRandomId())
  const announcementIdForNewClass = useRef(createRandomId())
  const [validated, setValidated] = useState(false);
  const [multiWeek, setMultiWeek] = useState(user.settings.multiWeek);
  const [differentTimes, setDifferentTimes] = useState(user.settings.differentTimes)
  const [numberOfWeeks, setNumberOfWeeks] = useState(user.settings.numberOfWeeks || 2)
  const [currentWeek, setCurrentWeek] = useState("A") //this is not the settings currentWeek - "A" should remain the default
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  const [selectedFontStyle, setSelectedFontStyle] = useState()

  
  const addWeeksToSchedule = async () => {
    if (item !== undefined) {
      if (!('G' in item.schedule)) {
        try {
          const docRef = doc(db, "users", user.uid);
    
          const userCopy = JSON.parse(JSON.stringify(user));
    
          const classIndex = userCopy.classes.findIndex(
            (c) => c.classId === data.classId
          );
    
          const newWeeks = {
            "E": {
              "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "F": {
              "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
            "G": {
              "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
              "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
              "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
            },
          };
    
          userCopy.classes[classIndex].schedule = { ...userCopy.classes[classIndex].schedule, ...newWeeks };
    
          // Update the document in Firestore
          await setDoc(docRef, userCopy);

          // Update the data state
          setData((prevData) => ({
            ...prevData,
            schedule: { ...prevData.schedule, ...newWeeks },
          }));
    
        } catch (error) {
          console.error("Error updating schedule: ", error);
        }
      }
    }
  };

  useEffect(() => {
    addWeeksToSchedule();
  }, []);

  function cycleWeeks(direction) {
    const weekLetterOptions = ["A", "B", "C", "D", "E", "F", "G"];
    let currentIndex = weekLetterOptions.indexOf(currentWeek);

    if (direction === "forward") {
      if (currentIndex === numberOfWeeks-1) {
        currentIndex = 0;
      } else {
        currentIndex++;
      }
    } else if (direction === "backward") {
      if (currentIndex === 0) {
        currentIndex = numberOfWeeks-1;
      } else {
        currentIndex--;
      }
    }
  
    setCurrentWeek(weekLetterOptions[currentIndex]);
  }

  const blankSchedule = {
    "A": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "B": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "C": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "D": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "E": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "F": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
    "G": {
      "Monday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Tuesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Wednesday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Thursday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Friday": {active: true, startTime: '00:00', endTime: '00:00'},
      "Saturday": {active: false, startTime: '00:00', endTime: '00:00'},
      "Sunday": {active: false, startTime: '00:00', endTime: '00:00'},
    },
  }

  const [data, setData] = useState({
    autoHappyBirthday: item?.autoHappyBirthday || null,
    classId: item?.classId,
    className: item?.className || null,
    period: item?.period || null,
    startTime: item?.startTime || '',
    endTime: item?.endTime || '',
    showMarquee: item?.showMarquee === false ? false : item?.showMarquee === true ? true : null,
    showClock: item?.showClock === false ? false : item?.showClock === true ? true : null,
    active: item?.active === false ? false : item?.active === true ? true : null,
    contentBoxes: item?.contentBoxes || [],
    orderOfAnnouncements: item?.orderOfAnnouncements || [],
    days: [],
    schedule: item?.schedule || blankSchedule,
    rosterData: item?.rosterData || null,
    background: item?.background || null,
    backgroundAnnouncements: item?.backgroundAnnouncements || null,
    backgroundClock: item?.backgroundClock || null,
    clockVersion: item?.clockVersion || null,
    clockFontColor: item?.clockFontColor || null,
  });

  const [alert, setAlert] = useState(null);
  // to stop the warning of calling setState of unmounted component
  useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  useEffect(() => {
    if (
      !data.className || data.className.length < 1 || data.className.length > 30 || 
      data.period?.length < 1 || data.period?.length > 5 || 
      /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className)
    ) {
      setValidated(false);
    } else {
      setValidated(true);
    }
  }, [data]);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validated) {
      try {
        const docRef = doc(db, "users", user.uid);
  
        const userCopy = JSON.parse(JSON.stringify(user));
  
        const newClass = {
          classId: classIdForNewClass.current,
          period: data.period,
          className: data.className,
          days: data.days,
          schedule: data.schedule,
          startTime: data.startTime,
          endTime: data.endTime,
          active: data.active === false ? false : true,
          showClock: data.showClock === false ? false : true,
          showMarquee: data.showMarquee === false ? false : true,
          orderOfAnnouncements: [announcementIdForNewClass.current],
          contentBoxes: [
            {
              contentBoxId: createRandomId(),
              heading: "Type here...",
              show: true,
              active: true
            }
          ]
        }
    
        const sampleAnnouncementToAdd = {
          html: "<p>This is a sample announcement.</p>",
          text: "This is a sample announcement.",
          active: true,
          show: true,
          lastEdited: formattedDate,
          dateCreated: formattedDate,
          classIds: [classIdForNewClass.current],
          id: announcementIdForNewClass.current
        }
  
        const classIndex = userCopy.classes.findIndex(
          (c) => c.classId === data.classId
        );
  
        // Update the content box in the document data
        if (item === undefined) {
          userCopy.classes.push(newClass);
          userCopy.announcements.push(sampleAnnouncementToAdd);
        } else {
          userCopy.classes[classIndex] = data
        }
  
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
  
        successAlert();
      } catch (error) {
        console.log('Error updating document:', error);
      }
    } else {
      invalidEntryAlert()
    }
  }

  const invalidEntryAlert = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Invalid entry!"
        onConfirm={() => hideInvalidEntryAlert()}
        onCancel={() => hideInvalidEntryAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      />
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Class Saved!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
    history.push("/add-edit-classes")
  };

  const hideInvalidEntryAlert = () => {
    setAlert(null);
  };

  function handleFormNonDays(e) {
    const { id, value, type, checked } = e.target;

    // Check if the input element is a checkbox
    if (type === 'checkbox') {
      // Update the state based on the checkbox's checked property
      setData((prevData) => ({
        ...prevData,
        [id]: checked, // Update the corresponding property in the state
      }));
    } else {
      // For non-checkbox inputs, update the state based on the input's value
      setData((prevData) => ({
        ...prevData,
        [id]: value,
      }));
    }
  }

  const handleTimesForSingleTimeClasses = (e) => {
    const newTime = e.target.value;

    const id = e.target.id

    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));

    for (const weekKey in updatedSchedule) {
      if (updatedSchedule.hasOwnProperty(weekKey)) {
        const week = updatedSchedule[weekKey];
        for (const dayKey in week) {
          if (week.hasOwnProperty(dayKey)) {
            // Update the startTime property for each day
            week[dayKey][id] = newTime;
          }
        }
      }
    }

    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  }

  const handleScheduleChange = (week, day, field, value) => {

    // Create a copy of the schedule to avoid mutating the state directly
    const updatedSchedule = JSON.parse(JSON.stringify(data.schedule));
    
    // Update the specified field in the schedule
    updatedSchedule[week][day][field] = value;
  
    // Set the updated schedule in your state or wherever you store it
    setData((prevData) => ({
      ...prevData,
      schedule: updatedSchedule,
    }));
  };

  const handleFontStyleChange = () => {
    console.log()
  }

  return (
    <>
      <div className="content">
        <Row style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh'}}>
          <Col md="6">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Add/Edit Class</CardTitle>
              </CardHeader>
              <CardBody>
                <Form
                  className="form-horizontal"
                  noValidate // Remove 'noValidate' if you want to use HTML5 validation as well
                  validated={validated.toString()}
                >
                  <Row>
                    <Col>
                      <label style={{ fontSize: '14px' }}>Class Name</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: 7th Grade Band"
                          type="text"
                          id="className"
                          value={data.className}
                          required // Add 'required' for HTML5 validation
                          valid={data.className?.length > 0 && data.className?.length <= 30} // Display valid feedback
                          invalid={data.className != null && data.className?.length === 0 || data.className?.length > 30 || /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className)}
                        />
                        {data.className?.length === 0 ? (
                          <FormFeedback invalid>Please provide a class name.</FormFeedback>
                        ) : data.className?.length > 30 ? (
                          <FormFeedback invalid>Class name is too long (max 30 characters).</FormFeedback>
                        ) : /["#%&'()+,\/:;<=>?@[\\\]^`{|}]/.test(data.className) ? (
                          <FormFeedback invalid>{"Your class contains invalid characters. Please avoid using the following characters: \" # % & ' ( ) + , / : ; < = > ? @ [ \\ ] ^ ` { | }"}</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                    <Col>
                      <label style={{ fontSize: '14px' }}>Period</label>
                      <FormGroup>
                        <Input
                          onChange={(e) => handleFormNonDays(e)}
                          placeholder="Ex: 1st"
                          type="text"
                          id="period"
                          value={data.period}
                          required
                          valid={data.period?.length > 0 && data.period?.length <= 4} // Display valid feedback
                          invalid={data.period != null && data.period?.length === 0 || data.period?.length > 4} // Display invalid feedback
                          >
                        </Input>
                        <small id="periodHelpBlock" class="form-text text-muted">
                          Must be between 1-4 characters.
                        </small>
                        {data.period?.length === 0 ? (
                          <FormFeedback invalid>Please provide a 4 character or less period identifier.</FormFeedback>
                        ) : data.period?.length > 4 ? (
                          <FormFeedback invalid>Period identifier is too long (max 4 characters).</FormFeedback>
                        ) : (
                          <FormFeedback valid>Looks good!</FormFeedback>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>


                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="showMarqueeTip" style={{ fontSize: '14px' }}>Marquee</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <UncontrolledTooltip
                        delay={0}
                        target={"showMarqueeTip"}
                        placement="top"
                      >
                        If checked, class will include a scrolling marquee you can use for announcements, etc.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="showMarquee" 
                          checked={data.showMarquee}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="showClockTip" style={{ fontSize: '14px' }}>Clock</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"showClockTip"}
                        placement="top"
                      >
                        If checked, class will include a clock.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="showClock" 
                          checked={data.showClock}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="activeTip" style={{ fontSize: '14px' }}>Active</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"activeTip"}
                        placement="top"
                      >
                        If checked, class will appear in classes dropdown.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="checkbox" 
                          id="active" 
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            position: 'initial', 
                            marginLeft: '0px',
                            transform: 'scale(2)'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    {/* <Col md="3" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="defaultColor" style={{ fontSize: '14px' }}>Box Background</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"defaultColor"}
                        placement="top"
                      >
                        If checked, class will appear in classes dropdown.
                      </UncontrolledTooltip>
                        <div 
                          onChange={(e) => handleFormNonDays(e)} 
                          type="color" 
                          id="active" 
                          checked={data.active}
                          defaultChecked={true}
                          style={{
                            border: 'solid 1px lightgrey',
                            backgroundColor: 'url(/colors5.png)',
                            height: '38px',
                            width: '100px',
                            cursor: 'pointer',
                            borderRadius: '3px',
                            textAlign: 'center'
                          }}
                        >
                          None
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="3" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="defaultFont" style={{ fontSize: '14px' }}>Default Font</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"defaultFont"}
                        placement="top"
                      >
                        If checked, class will appear in classes dropdown.
                      </UncontrolledTooltip>
                        <FontStylePicker selectedFont={selectedFontStyle} onChangeFunction={handleFontStyleChange} fromEditorToolbar={false} />
                      </FormGroup>
                    </Col> */}
                  </Row>



                {!differentTimes && 
                  <Row style={{ marginTop: "30px"}}>
                    <Col>
                      <UncontrolledTooltip
                        delay={0}
                        target={"timeTip"}
                        placement="top"
                      >
                        Providing class times will allow Lesson Launchpad to auto navigate to your next class when it's time for it to start.
                      </UncontrolledTooltip>
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleTimesForSingleTimeClasses(e)} type="time" id="startTime" value={data.schedule.A.Monday.startTime}/>
                      </FormGroup>
                    </Col>
                    <UncontrolledTooltip
                        delay={0}
                        target={"timeTip"}
                        placement="top"
                      >
                        Providing class times will allow Lesson Launchpad to auto navigate to your next class when it's time for it to start.
                      </UncontrolledTooltip>
                    <Col>
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleTimesForSingleTimeClasses(e)} type="time" id="endTime" value={data.schedule.A.Monday.endTime}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  }


                  {!differentTimes && !multiWeek &&
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"monday"}
                        placement="top"
                      >
                        Check if this class meets on Monday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.A.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"tuesday"}
                        placement="top"
                      >
                        Check if this class meets on Tuesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.A.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"wednesday"}
                        placement="top"
                      >
                        Check if this class meets on Wednesday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.A.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"thursday"}
                        placement="top"
                      >
                        Check if this class meets on Thursday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.A.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <UncontrolledTooltip
                        delay={0}
                        target={"friday"}
                        placement="top"
                      >
                        Check if this class meets on Friday.
                      </UncontrolledTooltip>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.A.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }




                  {multiWeek && !differentTimes &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week A
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule.A.Monday.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule.A.Tuesday.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule.A.Wednesday.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule.A.Thursday.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule.A.Friday.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>



                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                    Week B
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("B", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="monday" 
                        checked={data.schedule.B.Monday.active}
                        // value={"M"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule.B.Tuesday.active}
                        // value={"T"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                   <Input 
                          onChange={(e) => handleScheduleChange("B", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule.B.Wednesday.active}
                        // value={"W"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="thursday" 
                        checked={data.schedule.B.Thursday.active}
                        // value={"Th"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                    <Input 
                          onChange={(e) => handleScheduleChange("B", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                        id="friday" 
                        checked={data.schedule.B.Friday.active}
                        // value={"F"}
                        style={{
                          transform: 'scale(2)',
                          position: 'initial', 
                          marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                

                {numberOfWeeks === "3" && multiWeek && 
                <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  }

                {numberOfWeeks === "4" && multiWeek &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  </>
                  }
                </>
                }



                {numberOfWeeks === "5" && multiWeek && !differentTimes &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week E
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("E", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.E.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.E.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("E", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.E.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.E.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.E.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
                }




                {numberOfWeeks === "6" && multiWeek && !differentTimes &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week E
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("E", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.E.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.E.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("E", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.E.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.E.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.E.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week F
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("F", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.F.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.F.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("F", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.F.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.F.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.F.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
                }



                {numberOfWeeks === "7" && multiWeek && !differentTimes &&
                <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week C
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("C", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.C.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.C.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("C", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.C.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.C.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("C", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.C.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week D
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("D", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.D.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.D.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("D", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.D.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.D.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("D", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.D.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week E
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("E", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.E.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.E.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("E", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.E.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.E.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("E", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.E.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week F
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("F", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.F.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.F.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("F", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.F.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.F.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("F", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.F.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col style={{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                      Week G
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("G", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule.G.Monday.active}
                          // value={"M"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("G", "Tuesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="tuesday" 
                          checked={data.schedule.G.Tuesday.active}
                          // value={"T"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                     <Input 
                          onChange={(e) => handleScheduleChange("G", "Wednesday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="wednesday" 
                          checked={data.schedule.G.Wednesday.active}
                          // value={"W"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("G", "Thursday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="thursday" 
                          checked={data.schedule.G.Thursday.active}
                          // value={"Th"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                          onChange={(e) => handleScheduleChange("G", "Friday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="friday" 
                          checked={data.schedule.G.Friday.active}
                          // value={"F"}
                          style={{
                            transform: 'scale(2)',
                            position: 'initial', 
                            marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </>
                }
                


                  { differentTimes && !multiWeek &&
                  <>
                  <Row style={{marginTop: "30px", marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange("A", "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule["A"].Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Monday.active ? data.schedule["A"].Monday.startTime : null}/>
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                        <Input onChange={(e) => handleScheduleChange("A", "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Monday.active ? data.schedule["A"].Monday.endTime : null}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule["A"].Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Tuesday.active ? data.schedule["A"].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Tuesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Tuesday.active ? data.schedule["A"].Tuesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule["A"].Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Wednesday.active ? data.schedule["A"].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Wednesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Wednesday.active ? data.schedule["A"].Wednesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Thursday", "active", e.target.checked)}
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule["A"].Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Thursday.active ? data.schedule["A"].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Thursday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Thursday.active ? data.schedule["A"].Thursday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange("A", "Friday", "active", e.target.checked)}
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule["A"].Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule["A"].Friday.active ? data.schedule["A"].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange("A", "Friday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule["A"].Friday.active ? data.schedule["A"].Friday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                </>
                  }




                { differentTimes && multiWeek &&
                  <>
                  <Row style={{marginTop: "30px", marginBottom: '10px', display:'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <i className="nc-icon nc-minimal-left" style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("backward"))}/>
                      <h3 style={{marginLeft: '15px', marginRight: '15px', marginBottom: '0px'}}>Week {currentWeek}</h3>
                    <i className="nc-icon nc-minimal-right"style={{cursor: 'pointer'}} onClick={(() => cycleWeeks("forward"))}/>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                    <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                      <label id="monday" style={{ fontSize: '14px' }}>Monday</label>
                      <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                        <Input 
                          onChange={(e) => handleScheduleChange(currentWeek, "Monday", "active", e.target.checked)} 
                          type="checkbox" 
                          id="monday" 
                          checked={data.schedule[currentWeek]?.Monday.active}
                          // value={"M"}
                          style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                      <FormGroup>
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Monday.active ? data.schedule[currentWeek].Monday.startTime : null}/>
                      </FormGroup>
                    </Col>
                    <Col md="5">
                      <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                      <FormGroup>
                      <Input onChange={(e) => handleScheduleChange(currentWeek, "Monday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Monday.active ? data.schedule[currentWeek].Monday.endTime : null}/>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="tuesday" style={{ fontSize: '14px' }}>Tuesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="tuesday" 
                        checked={data.schedule[currentWeek]?.Tuesday.active}
                        // value={"T"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Tuesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Tuesday.active ? data.schedule[currentWeek].Tuesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="wednesday" style={{ fontSize: '14px' }}>Wednesday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="wednesday" 
                        checked={data.schedule[currentWeek]?.Wednesday.active}
                        // value={"W"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Wednesday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Wednesday.active ? data.schedule[currentWeek].Wednesday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="thursday" style={{ fontSize: '14px' }}>Thursday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="thursday" 
                        checked={data.schedule[currentWeek]?.Thursday.active}
                        // value={"Th"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Thursday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Thursday.active ? data.schedule[currentWeek].Thursday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                <Row style={{marginLeft: "10px", display:'flex', justifyContent: 'space-evenly'}}>
                  <Col md="2" style={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
                    <label id="friday" style={{ fontSize: '14px' }}>Friday</label>
                    <FormGroup style={{display: 'flex', alignItems: 'center'}}>
                      <Input 
                        onChange={(e) => handleScheduleChange(currentWeek, "Friday", "active", e.target.checked)} 
                        type="checkbox" 
                        id="friday" 
                        checked={data.schedule[currentWeek]?.Friday.active}
                        // value={"F"}
                        style={{transform: 'scale(2)', position: 'initial', marginLeft: '0px'
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>Start Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "startTime", e.target.value)} type="time" id="startTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.startTime : null}/>
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <label id="timeTip" style={{ fontSize: '14px' }}>End Time</label>
                    <FormGroup>
                    <Input onChange={(e) => handleScheduleChange(currentWeek, "Friday", "endTime", e.target.value)} type="time" id="endTime" value={data.schedule[currentWeek].Friday.active ? data.schedule[currentWeek].Friday.endTime : null}/>
                    </FormGroup>
                  </Col>
                </Row>
                </>
                  }



                <Row style={{marginTop: "30px", display:'flex', justifyContent: 'space-evenly'}}>
                  <NavLink to="/settings" style={{ color: 'black', marginRight: '50px' }}>
                    Need different scheduling options?
                  </NavLink>
                </Row>




                </Form>
              </CardBody>
              <CardFooter>
                <Button className="btn-round" color="info" type="submit"  onClick={(e) => handleSubmit(e)}>
                  Save
                </Button>
              </CardFooter>
            </Card>
            {alert}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default AddEditClassForm;

/*!

=========================================================
* Paper Dashboard PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import {React, useState, useEffect} from "react";
import { useSelector } from "react-redux";
import { selectUser } from "features/userSlice";
import { setDoc, doc } from "firebase/firestore";
import { db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { Editor } from '@tinymce/tinymce-react';
import { useHistory } from 'react-router-dom';
import { createRandomId } from "RandomId";
import ReactDatetime from "react-datetime";
import TipTapEditor from "/Users/michaeltucker/Documents/coding-projects/client/src/components/TipTapEditor.js";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  CardSubtitle,
  Table,
  CardBody,
  UncontrolledTooltip,
  Input,
  Container,
  FormGroup,
  Label
} from "reactstrap";

function AddEditAnnouncementForm(props) {

  const user = useSelector(selectUser);
  const classIndex = user.classes.findIndex((x) => x.classId === props.location.state.classId);
  const [alert, setAlert] = useState(null);
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1; // Months are zero-indexed, so add 1
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();
  const formattedDate = `${month}/${day}/${year}`;
  const [orderOfAnnouncements, setOrderOfAnnouncements] = useState();
  const [announcements, setAnnouncements] = useState();
  const [announcementsStorage, setAnnouncementsStorage] = useState();
  const [editedAnnouncement, setEditedAnnouncement] = useState();
  const [addEditAnnouncementModal, setAddEditAnnouncementModal] = useState(false);
  const [announcementToEdit, setAnnouncementToEdit] = useState();
  const [classDataForMultiAdd, setClassDataForMultiAdd] = useState(); 
  const [showDatePicker, setShowDatePicker] = useState(false);
  const days = ['Sun', 'Mon', 'Tues', 'Wed', 'Thur', 'Fri', 'Sat'];
  const daysLong = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'June', 'July', 'Aug', 'Sept', 'Octo', 'Nov', 'Dec'];
  const monthsLong = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  const suffix = ['', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st', 'nd', 'rd', 'th', 'th', 'th', 'th', 'th', 'th', 'th', 'st'   ]
  const [date, setDate] = useState( x => {
    let dateObject = new Date()
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    return dateData;
  });
  const today = new Date().toISOString().slice(0,10)
  const [dateCreatedSortOrder, setDateCreatedSortOrder] = useState("asc");
  const [lastEditedSortOrder, setLastEditedSortOrder] = useState("asc");

  useEffect(() => {
    if (editedAnnouncement) {
      handleAnnouncementChange();
    }
  }, [editedAnnouncement]);

  const handleSubmit = async (announcementId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));

      if (!Array.isArray(userCopy.announcements)) {
        userCopy.announcements = [];
      }

      if (announcementToEdit.announcementId === 0) { //If new announcement
        announcementToEdit.announcement.classIds = classDataForMultiAdd.filter((data) => data.add === true).map((data) => data.classId);
        userCopy.announcements.push(announcementToEdit.announcement);
        if (!Array.isArray(userCopy.classes[classIndex].orderOfAnnouncements)) {
          userCopy.classes[classIndex].orderOfAnnouncements = [];
        } 
        userCopy.classes[classIndex].orderOfAnnouncements.push(announcementToEdit.announcement.id)
      } else {
        const foundAnnouncement = userCopy.announcements.find(announcement => announcement.id === announcementId);
        const foundAnnouncementIndex = userCopy.announcements.findIndex(announcement => announcement.id === announcementId);
        const classIds = classDataForMultiAdd.filter((data) => data.add === true).map((data) => data.classId);
  
        if (foundAnnouncement) {
          // Update the found announcement
          foundAnnouncement.html = announcementToEdit.announcement.html;
          foundAnnouncement.text = announcementToEdit.announcement.text;
          foundAnnouncement.active = announcementToEdit.announcement.active;
          foundAnnouncement.show = announcementToEdit.announcement.show;
          foundAnnouncement.lastEdited = formattedDate;
          foundAnnouncement.classIds = classIds,

          // Update each classes orderOfAnnouncementsArray
          classIds.forEach((classId) => {
            const classIndex = userCopy.classes.findIndex(x => x.classId === classId);
            const classObj = userCopy.classes[classIndex];
        
            // Check if editedAnnouncement.id already exists in the orderOfAnnouncements array
            if (!classObj.orderOfAnnouncements.includes(editedAnnouncement.id)) {
                // If it doesn't exist, push it
                classObj.orderOfAnnouncements.push(editedAnnouncement.id);
            }
        });

        // Fix for duplicate announcementId's that was causing inability to move announcements - 2/14/24
        classIds.forEach((classId) => {
          const classIndex = userCopy.classes.findIndex(x => x.classId === classId);
          const classObj = userCopy.classes[classIndex];
      
          // Remove duplicate occurrences of editedAnnouncement.id from orderOfAnnouncements array
          classObj.orderOfAnnouncements = classObj.orderOfAnnouncements.filter((announcementId, index, array) => {
              // Keep the first occurrence of editedAnnouncement.id and remove duplicates
              return array.indexOf(announcementId) === index;
          });
        });
  
        } else {
          console.log("Announcement not found for editing:", announcementToEdit);
        }
        
        userCopy.announcements[foundAnnouncementIndex] = foundAnnouncement;
      }
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
  
      successAlert();
  
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  useEffect(() => {
    setAnnouncements((prev) => sortAnnouncementsByDateCreated(prev));
    setAnnouncementsStorage((prev) => sortAnnouncementsByDateCreated(prev));
  }, [user, date, orderOfAnnouncements, dateCreatedSortOrder, lastEditedSortOrder]);

  const handleDeleteAnnouncement = async (announcementId) => {
    try {
      const docRef = doc(db, "users", user.uid);

      const userCopy = JSON.parse(JSON.stringify(user));

      const announcementIndex = userCopy.announcements.findIndex(
        (a) => a.id === announcementId
      );

      // Update the announcements in the document data
      userCopy.announcements.splice(announcementIndex, 1)

      // Update the orderOfAnnouncements Array in the document data
      if (userCopy.classes[classIndex].orderOfAnnouncements.length > 1) {
        userCopy.classes[classIndex].orderOfAnnouncements = userCopy.classes[classIndex].orderOfAnnouncements.filter(id => id !== announcementId);
      } else {
        userCopy.classes[classIndex].orderOfAnnouncements = []
      }

      // Update the document in Firestore
      await setDoc(docRef, userCopy);

      successDelete()

    } catch (error) {
      console.log(error)
    }
  }

  const handleMoveToStorage = async (announcementId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      const announcementIndex = userCopy.announcements.findIndex(
        (a) => a.id === announcementId
      );
  
      // Update the announcements in the document data
      userCopy.announcements[announcementIndex].active = false;
  
      // Update the orderOfAnnouncements Array in the document data
      userCopy.classes[classIndex].orderOfAnnouncements = userCopy.classes[classIndex].orderOfAnnouncements.filter(id => id !== announcementId);
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
    } catch (error) {
      console.log(error)
    }
  }
  
  const handleMoveToActive = async (announcementId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      const announcementIndex = userCopy.announcements.findIndex(
        (a) => a.id === announcementId
      );
  
      // Update the announcements in the document data
      userCopy.announcements[announcementIndex].active = true;
  
      // Update the orderOfAnnouncements Array in the document data
      userCopy.classes[classIndex].orderOfAnnouncements.push(announcementId);
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
        
    } catch (error) {
      console.log(error)
    }
  }

  const handleMoveUp = async (announcementId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      const index = userCopy.classes[classIndex].orderOfAnnouncements.indexOf(announcementId);
  
      if (index > 0) {
        // Move the element one position forward
        const newArray = [...userCopy.classes[classIndex].orderOfAnnouncements];
        [newArray[index - 1], newArray[index]] = [newArray[index], newArray[index - 1]];
        userCopy.classes[classIndex].orderOfAnnouncements = newArray;
      }
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
  
    } catch (error) {
      console.log(error)
    }
  }
  
  const handleMoveDown = async (announcementId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      const index = userCopy.classes[classIndex].orderOfAnnouncements.indexOf(announcementId);
  
      if (index < userCopy.classes[classIndex].orderOfAnnouncements.length - 1) {
        // Move the element one position down
        const newArray = [...userCopy.classes[classIndex].orderOfAnnouncements];
        [newArray[index], newArray[index + 1]] = [newArray[index + 1], newArray[index]];
        userCopy.classes[classIndex].orderOfAnnouncements = newArray;
      }
  
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
  
    } catch (error) {
      console.log(error)
    }
  }

  const handleAnnouncementChange = () => {
    if (editedAnnouncement?.text?.length > 100) {
      announcementCharacterLimitAlert()
    } else {
      setAnnouncementToEdit({
        ...announcementToEdit, // Spread the top-level properties
        announcement: {
          ...announcementToEdit?.announcement, // Spread the existing properties of the nested announcement
          html: editedAnnouncement.html,
          text: editedAnnouncement.text
        }
      });
    }
  };

  const handleCheckboxClickForMultiAdd = (classId) => {
    setClassDataForMultiAdd((prev) =>
      prev.map((x) =>
        x.classId === classId ? { ...x, add: !x.add } : x
      )
    );
    setEditedAnnouncement({...announcementToEdit.announcement});
  };

  const toggleAnnouncementModal = (announcementId) => {
    hideAlert()
    if (announcementId != undefined) {
      const foundAnnouncement = user.announcements.find(announcement => announcement.id === announcementId);
      setEditedAnnouncement({...foundAnnouncement});
      setAnnouncementToEdit({announcementId: announcementId, announcement: foundAnnouncement});
      setActiveDates({startDate: foundAnnouncement?.startDate || '', endDate: foundAnnouncement?.endDate || ''})
      setClassDataForMultiAdd(user.classes.map((x) => ({ className: x.className, classId: x.classId, add: foundAnnouncement?.classIds?.includes(x.classId) ? true : false })))
    } else {
      setClassDataForMultiAdd(user.classes.map((x) => ({ className: x.className, classId: x.classId, add: x.classId === props.location.state.classId ? true : false })))
      setAnnouncementToEdit(
        {
          announcementId: 0,
          announcement:{
            // html: "",
            // text: "",
            active: true,
            show: true,
            lastEdited: formattedDate,
            dateCreated: formattedDate,
            classIds: classDataForMultiAdd.filter((data) => data.add === true).map((data) => data.classId),
            id: createRandomId(),
            startDate: date.databaseFormattedDate,
            endDate: date.databaseFormattedDate
          }
        }
      );
    }
    //reset classDataForMultiAdd to original state when modal gets closed.
    setAddEditAnnouncementModal(!addEditAnnouncementModal)
  }

  const warningWithConfirmAndCancelMessage = (id) => {
    setAlert(
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => handleDeleteAnnouncement(id)}
        onCancel={() => cancelDelete()}
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
        btnSize=""
      >
        You will not be able to recover this announcement!
      </ReactBSAlert>
    );
  };

  const successDelete = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your announcement has been deleted.
      </ReactBSAlert>
    );
  };

  const cancelDelete = () => {
    setAlert(
      <ReactBSAlert
        danger
        style={{ display: "block", marginTop: "-100px" }}
        title="Cancelled"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        Your announcement is safe :)
      </ReactBSAlert>
    );
  };

  const successAlert = () => {
    setAlert(
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Announcements Saved!"
        // onConfirm={() => history.push(props.history.location.state.path.pathname, {classId: props.location.state.classId, showMarquee: props.location.state.showMarquee, showClock: props.location.state.showClock})}
        onConfirm={() => toggleAnnouncementModal()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
      </ReactBSAlert>
    );
  };

  const announcementCharacterLimitAlert = () => {
    setAlert(
      <ReactBSAlert
        style={{ display: "block", marginTop: "-100px" }}
        title="Announcement too long!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnBsStyle="info"
        btnSize=""
      >
        This announcement is too long. Consider splitting a longer announcement into multiple smaller announcements.
      </ReactBSAlert>
    );
  }

  const hideAlert = () => {
    setAlert(null);
  };

  useEffect(() => {
    const currentOrderOfAnnouncements = user.classes[classIndex]?.orderOfAnnouncements;
    setOrderOfAnnouncements(currentOrderOfAnnouncements);
  
    let activeAnnouncements = [];
    let storageAnnouncements = [];
  
    const classId = props.location.state.classId;
    const today = new Date(date.year, date.month, date.day);
    today.setHours(0, 0, 0, 0); // Normalize today's date
  
    if (user.settings.useActiveDates) {
      activeAnnouncements = user.announcements?.filter(x => {
        if (x.startDate === undefined && x.classIds.includes(classId)) {
            return true;
        } else if (x.startDate && x.endDate) {
            const [startYear, startMonth, startDay] = x.startDate.split('-');
            const [endYear, endMonth, endDay] = x.endDate.split('-');
            const startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
            const endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0, 0);
            const dayToCompare = new Date(date.year, date.month, date.day, 0, 0, 0, 0);
            return x.classIds.includes(classId) && startDate <= dayToCompare && endDate >= dayToCompare;
        }
        return false; // If x.startDate or x.endDate is undefined, exclude the announcement.
    }) || [];
  
      storageAnnouncements = user.announcements?.filter(x => {
        if (x.startDate && x.endDate) {
          const [startYear, startMonth, startDay] = x.startDate?.split('-');
          const [endYear, endMonth, endDay] = x.endDate?.split('-');
          const startDate = new Date(startYear, startMonth - 1, startDay, 0, 0, 0, 0);
          const endDate = new Date(endYear, endMonth - 1, endDay, 0, 0, 0, 0);
          const dayToCompare = new Date(date.year, date.month, date.day, 0, 0, 0, 0)
          return x.classIds.includes(classId) && (startDate > dayToCompare || startDate < dayToCompare && endDate < dayToCompare);
        }
      }) || [];
    } else {
      activeAnnouncements = user.announcements?.filter(x => x.classIds.includes(classId) && x.active) || [];
      storageAnnouncements = user.announcements?.filter(x => x.classIds.includes(classId) && !x.active) || [];
    }
  
    if (currentOrderOfAnnouncements) {
      activeAnnouncements.sort((a, b) => {
        const indexA = currentOrderOfAnnouncements.indexOf(a.id);
        const indexB = currentOrderOfAnnouncements.indexOf(b.id);
        return indexA - indexB;
      });
  
      storageAnnouncements.sort((a, b) => {
        const indexA = currentOrderOfAnnouncements.indexOf(a.id);
        const indexB = currentOrderOfAnnouncements.indexOf(b.id);
        return indexA - indexB;
      });
    }
  
    setAnnouncements(activeAnnouncements);
    setAnnouncementsStorage(storageAnnouncements);
  
    setClassDataForMultiAdd(user.classes?.map(x => ({
      className: x.className,
      classId: x.classId,
      add: x.classId === classId
    })));
  
  }, [user, date, props.location.state.classId, orderOfAnnouncements]);
  

  function convertDateFormat(startDate, endDate) {
    // Split the input date string on "/"
    const startParts = startDate.split('-');
    const endParts = endDate.split('-');
    // Rearrange the parts to the desired format "MM/DD/YYYY"
    const newStart = `${startParts[1]}/${startParts[2]}/${startParts[0]}`;
    const newEnd = `${endParts[1]}/${endParts[2]}/${endParts[0]}`;
    return startDate === endDate ? newStart : `${newStart} - ${newEnd}`;
  }

  const handleDateSet = (e) => {
    let dateObject = new Date(e._d.getFullYear(), e._d.getMonth(), e._d.getDate())
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    }
    setDate(dateData)
    setShowDatePicker(!showDatePicker);
  }

  const handleDateForward = () => {
    let dateObject = new Date(date.year, date.month, date.day);
  
    // Move to the next day by incrementing the date, not by adding milliseconds
    dateObject.setDate(dateObject.getDate() + 1);
  
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    };
  
    setDate(dateData);
  };

  const handleDateBackward = () => {
    let dateObject = new Date(date.year, date.month, date.day);
  
    // Move to the previous day by decrementing the date
    dateObject.setDate(dateObject.getDate() - 1);
  
    let dateData = {
      dayName: `${daysLong[dateObject.getDay()]}`,
      dateString: `${monthsLong[dateObject.getMonth()]} ${dateObject.getDate()}${suffix[dateObject.getDate()]}, ${dateObject.getFullYear()}`,
      year: dateObject.getFullYear(),
      month: dateObject.getMonth(),
      day: dateObject.getDate(),
      databaseFormattedDate: dateObject.toLocaleDateString('en-CA')
    };
  
    setDate(dateData);
  };

  // This is to prevent error from TipTapEditor
  const handleOnBlur = () => {
    return
  }

  const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = (announcement) => {
    setActiveDatesModal(!activeDatesModal)
    setEditedAnnouncement(announcement)
  }

  const [activeDates, setActiveDates] = useState( editedAnnouncement && {
    startDate: editedAnnouncement?.startDate === undefined ? "" : editedAnnouncement?.startDate,
    endDate: editedAnnouncement?.endDate === undefined ? "" : editedAnnouncement?.endDate
  });

  const handleActiveDateChange = (event) => {
    const { name, value } = event.target;
    setActiveDates(prevDates => ({
      ...prevDates,
      [name]: value
    }));
  };

  const saveAnnouncementActiveDates = async (announcementId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));

      const announcementIndex = userCopy.announcements.findIndex(
        (a) => a.id === announcementId
      );
 
      // Update the announcements in the document data
      userCopy.announcements[announcementIndex].startDate = activeDates.startDate || date.databaseFormattedDate;
      userCopy.announcements[announcementIndex].endDate = activeDates.endDate || date.databaseFormattedDate;

      // Update the document in Firestore
      await setDoc(docRef, userCopy);

      toggleSetActiveDatesModal();
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const sortAnnouncementsByDateCreated = (announcements) => {
  if (!Array.isArray(announcements)) {
    // console.warn("Invalid announcements data. Expected an array but got:", announcements);
    return []; // Return an empty array if announcements is not iterable
  }

  return [...announcements].sort((a, b) => {
    const dateA = new Date(a.dateCreated || 0); // Default to epoch if dateCreated is missing
    const dateB = new Date(b.dateCreated || 0); // Default to epoch if dateCreated is missing
    return dateCreatedSortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });
};

const sortAnnouncementsByLastEdited = (announcements) => {
  return [...announcements].sort((a, b) => {
    const dateA = new Date(a.lastEdited);
    const dateB = new Date(b.lastEdited);
    return lastEditedSortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });
};

const handleSortByDateCreated = () => {
  const newSortOrder = dateCreatedSortOrder === "asc" ? "desc" : "asc";
  setDateCreatedSortOrder(newSortOrder);

  setAnnouncements((prev) => sortAnnouncementsByDateCreated(prev));
  setAnnouncementsStorage((prev) => sortAnnouncementsByDateCreated(prev));
};

const handleSortByLastEdited = () => {
  const newSortOrder = lastEditedSortOrder === "asc" ? "desc" : "asc";
  setLastEditedSortOrder(newSortOrder);

  setAnnouncements((prev) => sortAnnouncementsByLastEdited(prev));
  setAnnouncementsStorage((prev) => sortAnnouncementsByLastEdited(prev));
};

  return (
    <div className="content">
      <Modal contentClassName="content-box-storage-table" isOpen={addEditAnnouncementModal} toggle={toggleAnnouncementModal}>
          <ModalHeader toggle={toggleAnnouncementModal}/>
          <ModalBody >
          Enter your announcement below. Each announcement is limited to 100 characters. Longer announcements should be split into multiple announcements.
            <Card className="mt-2" style={{backgroundImage:  user.classes[classIndex]?.backgroundAnnouncements || ''}}>
              <CardHeader>
                <CardTitle tag="h2">
                  <TipTapEditor setEditedContent={setEditedAnnouncement} initialValue={announcementToEdit?.announcement?.html || null} height={null} handleOnBlur={handleOnBlur} settings={user.settings}/>
                </CardTitle>
              </CardHeader>
            </Card>
            Add this announcement to: 
            {classDataForMultiAdd && classDataForMultiAdd.map((x) => 
              <div>
                <Input type="checkbox" checked={x.add} onChange={() => handleCheckboxClickForMultiAdd(x.classId)}></Input>
                {x.className}
              </div>
            )}
          </ModalBody>
          <div className="m-2 ml-5">
            <Button 
              variant="primary" 
              size="lg"
              onClick={() => handleSubmit(announcementToEdit.announcementId)}
            >
              Save
            </Button>
          </div>
        </Modal>

        {editedAnnouncement && announcementToEdit && 
        <Modal 
            isOpen={activeDatesModal}
            toggle={toggleSetActiveDatesModal}
            backdrop={true}
            scrollable
            style={{padding: '20px'}}
          >
            <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
            <br></br>
            <ModalBody>
              <Container>
                <Row xs="2">
                  <FormGroup>
                    <Label for="startDate">
                      Start Date
                    </Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        // defaultValue={"2024-02-02"}
                        value={activeDates?.startDate || date.databaseFormattedDate}
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="endDate">
                      End Date
                    </Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        value={activeDates?.endDate || date.databaseFormattedDate}
                    >
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
                </Row>
              </Container>
            </ModalBody>
            <Button onClick={() => saveAnnouncementActiveDates(editedAnnouncement?.id)}>Save Active Dates</Button>
          </Modal>
          }
  

                      <Card>
                        <Row>
                          <Col xs="4"/>
                          <Col xs="4">
                          {user.settings.useActiveDates ? (
                            <div className="contentBoxTableDatePicker">
                              {/* <UncontrolledTooltip
                                delay={0}
                                target=""
                                placement="top"
                              >
                                  Functionality Coming Soon
                              </UncontrolledTooltip> */}
                              <Button
                                className="btn-icon btn-neutral"
                                color="secondary"
                                size="md"
                                type="button"
                                onClick={() => handleDateBackward()}
                              >
                                <i className="nc-icon nc-minimal-left" />
                              </Button>
                                { showDatePicker ? (
                                  <ReactDatetime
                                    // timeFormat={"h:mm:a"}
                                    // value={localAgendaBoxData?.startTime}
                                    open
                                    initialValue={date}
                                    className="present-date-picker"
                                    timeFormat={false}
                                    closeOnSelect
                                    closeOnClickOutside
                                    onClose={() => setShowDatePicker(!showDatePicker)}
                                    onChange={(e) => handleDateSet(e)}
                                    inputProps={{
                                      placeholder: "Time Picker Here"
                                    }}
                                  />
                                ):(
                                  <div style={{marginTop: '0px', cursor: 'pointer'}} onClick={() => setShowDatePicker(!showDatePicker)}>
                                    <div style={{textAlign: 'center', fontSize: '20px'}}>{date.dayName}</div>
                                    <div style={{textAlign: 'center', fontSize: '15px'}}>{date.dateString}</div>
                                  </div>
                                )}
                              <Button
                                className="btn-icon btn-neutral"
                                color="secondary"
                                size="md"
                                type="button"
                                onClick={() => handleDateForward()}
                              >
                                <i className="nc-icon nc-minimal-right" />
                              </Button>
                            </div>
                          )
                          : null }
                            </Col>
                            <Col xs="4"/>
                          </Row>
                        <CardHeader>
                          <CardTitle tag="h4">Active Announcements</CardTitle>
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are the announcements that will appear on your "Present" screen on the selected date.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are the announcements that will appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-left" id="Announcement" style={{width: user.settings.useActiveDates ? '35%' : '45%'}}>Announcement</th>
                                {user.settings.useActiveDates && <th className="text-center" id="active-dates" style={{width: '20%'}}>Active Dates</th>}
                                <th
                                  className="text-center"
                                  id="date-created"
                                  style={{ width: "10%", cursor: "pointer" }}
                                  onClick={handleSortByDateCreated}
                                >
                                  Date Created {dateCreatedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th
                                  className="text-center"
                                  id="last-edited"
                                  style={{ width: "10%", cursor: "pointer" }}
                                  onClick={handleSortByLastEdited}
                                >
                                  Last Edited {lastEditedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th className="text-center" id="Order" style={{width: '10%'}}>Order</th>
                                <th className="text-center" style={{width: '15%'}}>Actions</th>
                              </tr>
                            </thead>
                            {user && props && announcements && announcements.map((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-left">{item.text}</td>
                                {user.settings.useActiveDates && <td className="text-center">{item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) : 'n/a'}</td> }
                                <td className="text-center">{item.dateCreated}</td>
                                <td className="text-center">{item.lastEdited}</td>
                                {/* <td className="text-center">{item.active ? <i className="fa fa-check" /> : <i className="fa fa-times" />}</td> */}
                                <td className="text-center">
                                <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159182282"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveUp(item.id)}
                                  >
                                  <i className="nc-icon nc-minimal-up" />
                                  </Button>
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="secondary"
                                    id="tooltip159185983"
                                    size="md"
                                    type="button"
                                    onClick={() => handleMoveDown(item.id)}
                                  >
                                  <i className="nc-icon nc-minimal-down" />
                                  </Button>
                                </td>
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip159182735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleAnnouncementModal(item.id)}
                                  >
                                    <i className="fa fa-edit" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip153942735"
                                        size="md"
                                        type="button"
                                        onClick={() => handleMoveToStorage(item.id)}
                                        >
                                        <i className="nc-icon nc-box" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip153942735"
                                      >
                                        Move to Storage
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.id)}
                                  >
                                    <i className="fa fa-trash" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )})}
                          </Table>
                          {alert}
                          <button
                              style={{
                                background: '#0bc0df',
                                border: '1px solid #0bc0df',
                                color: 'white',
                                margin: '2px',
                                borderRadius: '4px',
                                outline: 'none',
                                outlineColor: '#0bc0df',
                                font: 'Montserrat',
                                fontSize: '14px',
                                textTransform: 'uppercase',
                                fontWeight: '600',
                                padding: '10px 20px',
                                width: '250px',
                                height: '40px',
                                boxShadow: '0 6px 10px -4px rgba(0, 0, 0, 0.15)',
                                height: '40px', 
                              }}
                              onClick={() => toggleAnnouncementModal()}
                            >
                              Add Announcement
                            </button>
                        </CardBody>
                      </Card>






                      <Card>
                        <CardHeader>
                          {user.settings.useActiveDates ? (
                            <CardTitle tag="h4">Inactive Announcements</CardTitle>
                          ) : (
                            <CardTitle tag="h4">Announcement Storage</CardTitle>
                          )}
                          {user.settings.useActiveDates ? (
                            <CardSubtitle>These are the announcements that will NOT appear on your "Present" screen on the selected date.</CardSubtitle>
                          ) : (
                            <CardSubtitle>These are announcements that are in storage and will NOT appear on your "Present" screen.</CardSubtitle>
                          )}
                        </CardHeader>
                        <CardBody>
                          <Table responsive>
                            <thead className="text-primary">
                              <tr>
                                <th className="text-left" id="Announcement" style={{width: user.settings.useActiveDates ? '45%' : '65%'}}>Announcement</th>
                                {user.settings.useActiveDates && <th className="text-center" id="active-dates" style={{width: '20%'}}>Active Dates</th>}
                                <th
                                  className="text-center"
                                  id="date-created"
                                  style={{ width: "12%", cursor: "pointer" }}
                                  onClick={handleSortByDateCreated}
                                >
                                  Date Created {dateCreatedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th
                                  className="text-center"
                                  id="last-edited"
                                  style={{ width: "12%", cursor: "pointer" }}
                                  onClick={handleSortByLastEdited}
                                >
                                  Last Edited {lastEditedSortOrder === "asc" ? "↑" : "↓"}
                                </th>
                                <th className="text-center" style={{width: '15%'}}>Actions</th>
                              </tr>
                            </thead>
                            {user && props && announcementsStorage && announcementsStorage.map(((item, index) => {
                            return(
                            <tbody key={index}>
                              <tr>
                                <td className="text-left">{item.text}</td>
                                {user.settings.useActiveDates && <td className="text-center">{item?.startDate !== undefined ? convertDateFormat(item.startDate, item.endDate) : 'n/a'}</td> }
                                <td className="text-center">{item.dateCreated}</td>
                                <td className="text-center">{item.lastEdited}</td>                             
                                <td className="text-center">
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="info"
                                    id="tooltip159182735"
                                    size="md"
                                    type="button"
                                    onClick={() => toggleAnnouncementModal(item.id)}
                                  >
                                    <i className="fa fa-edit" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip159182735"
                                  >
                                    Edit
                                  </UncontrolledTooltip>
                                  {user.settings.useActiveDates ? (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip946933335"
                                        size="md"
                                        type="button"
                                        onClick={() => toggleSetActiveDatesModal(item)}
                                      >
                                        <i className="fa fa-calendar"/>
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip946933335"
                                      >
                                        Set Active Dates
                                      </UncontrolledTooltip>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        className="btn-icon btn-neutral"
                                        color="info"
                                        id="tooltip159182296"
                                        size="md"
                                        type="button"
                                        onClick={() => handleMoveToActive(item.id)}
                                      >
                                        <i className="nc-icon nc-basket" />
                                      </Button>
                                      <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip159182296"
                                      >
                                        Move to Active
                                      </UncontrolledTooltip>
                                    </>
                                  )}
                                  <Button
                                    className="btn-icon btn-neutral"
                                    color="danger"
                                    id="tooltip808966390"
                                    size="md"
                                    type="button"
                                    onClick={() => warningWithConfirmAndCancelMessage(item.id)}
                                  >
                                    <i className="fa fa-trash" />
                                  </Button>
                                  <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390"
                                  >
                                    Delete
                                  </UncontrolledTooltip>
                                </td>
                              </tr>
                              </tbody>
                            )}))}
                          </Table>
                          {alert}
                        </CardBody>
                      </Card>
    </div>
  );
}

export default AddEditAnnouncementForm;

import React from 'react';
import { useParams, Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'reactstrap';
import logo from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/launchpad.png";
import blogs from "/Users/michaeltucker/Documents/coding-projects/client/src/blogs.js";

const BlogArticle = () => {
    const { blogId } = useParams();

    // Find the blog by id
    const selectedBlog = blogs.find((blog) => blog.id === blogId);

    return (
        <Row style={{ padding: '20px 130px' }}>
            {/* Sidebar */}
            <Col md="3">
                <Card style={{ marginTop: '50px', border: '1px solid black', padding: '20px' }}>
                    <h4>Blog List</h4>
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {blogs.filter(blog => blog.show).map((blog) => (
                            <li key={blog.id} style={{ marginBottom: '10px' }}>
                                <Link to={`/blog/${blog.id}`} style={{ textDecoration: 'none', color: '#004aad' }}>
                                    {blog.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                </Card>
                <Button tag={Link} to="/auth/register" style={{ borderRadius: '12px', width: '100%', fontSize: '20px', height: '50px' }}>
                    Try it now!
                </Button>
            </Col>

            {/* Blog Content */}
            <Col md="9">
                <div style={{ padding: '20px' }}>
                    {selectedBlog ? (
                        <>
                            <h1 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '4rem', textShadow: '1px 1px 0px #5271FF', marginTop: '3%'}}>
                                <b>{selectedBlog.title}</b>
                            </h1>
                            {selectedBlog.content}
                        </>
                    ) : (
                        <div>
                            <h1>Blog Not Found</h1>
                            <p>Sorry, the blog you're looking for doesn't exist.</p>
                        </div>
                    )}
                </div>
            </Col>
        </Row>
    );
};

export default BlogArticle;

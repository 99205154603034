import React, { useState } from 'react'
import { Row, Card, CardBody, Input, Col, CardFooter} from "reactstrap";
import TipTapEditor from "./TipTapEditor";

export default function Whiteboard({props}) { 

    const [initialValueWhiteboard, setInitialValueWhiteboard] = useState( x => {
      const savedNotes = localStorage.getItem('whiteboardContent-' + props.current);
      if (savedNotes !== null && savedNotes !== "undefined") {
          return JSON.parse(savedNotes);
      } else {
          return {content: {
              "type": "doc",
              "content": [
                  {
                      "type": "paragraph",
                      "attrs": {
                          "textAlign": "left"
                      },
                      "content": [
                          {
                              "text": "Type here...",
                              "type": "text",
                              "marks": [
                                  {
                                      "type": "textStyle",
                                      "attrs": {
                                          "fontSize": "14px",
                                          "color": null,
                                          "fontFamily": null,
                                          "lineHeight": null
                                      }
                                  }
                              ]
                          }
                      ]
                  },
              ]
          }}
      }
    })

    const [whiteboardContent, setWhiteboardContent] = useState()

    const saveWhiteboardContent = () => {
      localStorage.setItem('whiteboardContent-' + props.current, JSON.stringify(whiteboardContent))
    }

    const [editorActive, setEditorActive] = useState(false)

  return (    
      <>
      {!editorActive ? (
        <Row onClick={() => setEditorActive(!editorActive)} style={{ cursor: 'pointer', justifyContent: 'center', alignItems: 'flex-end', position: 'absolute', top: '0', width: '100%' }}>
        Click Here for Text Editor
        </Row>
      ):(
        <>
          <TipTapEditor setEditedContent={setWhiteboardContent} initialValue={initialValueWhiteboard.content} handleOnBlur={saveWhiteboardContent} readOnly={false} singleLineFormattingToolbar={true}/>
        </>

      )}
    </> 
  )
}

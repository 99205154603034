import React, { useState, useEffect, useRef } from "react";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip} from "reactstrap";
import CanvaBox from "components/CanvaBox";
import MultiBox from "components/MultiBox";
import CountdownBox from "components/CountdownBox";
import GoogleDocSheetBox from "components/GoogleDocSheetBox";
import CarouselBox from "components/CarouselBox";
import PdfBox from "components/PdfBox";
import PowerpointBox from "components/PowerpointBox";
import IframeBox from "components/IframeBox";
import ContentBox from "components/ContentBox";
import GoogleSlidesBox from "/Users/michaeltucker/Documents/coding-projects/client/src/components/GoogleSlidesBox.js"
import TimerStopwatchContentBox from "components/TimerStopwatchContentBox";
import AgendaBox from "components/AgendaBox";

export default function FullscreenModal({fullscreenModal, toggleFullscreenModal, box, navbarMD, classIdRef, view, user, multiBox, addContentBox, updateContentBox, navigationVisible}) {

    const index = "fullscreenModal"

    return (
        <Modal isOpen={fullscreenModal} fullscreen={true} toggle={toggleFullscreenModal} modalClassName={"fullscreen-modal"} unmountOnClose={false}>
            <ModalHeader toggle={toggleFullscreenModal}></ModalHeader>
            <ModalBody style={{padding: '0px'}}>
            {box === undefined ?
                <></>
              : box.slideshow ?
                <>
                    <GoogleSlidesBox
                      key={`googleSlidesBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      view={view}
                      user={user}
                      classId={classIdRef}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
              : box.googleDocSheet ?
              <>
                  <GoogleDocSheetBox
                    key={`googleDocBox-${classIdRef}-${index}-${box.contentBoxId}`}
                    content={box}
                    navbarMD={navbarMD}
                    view={view}
                    user={user}
                    classId={classIdRef}
                    multiBox={false}
                    updateContentBox={updateContentBox}
                    navigationVisible={navigationVisible}
                    inFullscreenModal={true}
                  />
              </>
              : (box.timer || box.stopwatch) ? 
                <>
                    <TimerStopwatchContentBox
                      key={`timerStopwatchBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef}
                      view={view}
                      user={user}
                      multiBox={false}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.agendaBox) ? 
                <>
                    <AgendaBox
                      key={`agendaBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef}
                      view={view}
                      user={user}
                      multiBox={false}
                      clockKey={clockKey}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.multiBox) ? 
                <>
                    <MultiBox
                      key={`multiBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef}
                      view={view}
                      user={user}
                      addContentBox={addContentBox}
                      multiBox={false}
                      navigationVisible={navigationVisible}
                    />
                </>
              : (box.carousel) ? 
              <>
                  <CarouselBox
                    key={`carouselBox-${classIdRef}-${index}-${box.contentBoxId}`}
                    content={box}
                    navbarMD={navbarMD}
                    classId={classIdRef}
                    view={view}
                    user={user}
                    addContentBox={addContentBox}
                    multiBox={false}
                    navigationVisible={navigationVisible}
                  />
              </>
              : (box.canva) ?
                <>
                    <CanvaBox
                      key={`canvaBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef}
                      view={view}
                      user={user}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
                : (box.iFrame) ?
                <>
                    <IframeBox
                      key={`IframeBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef}
                      view={view}
                      user={user}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                      inFullscreenModal={true}
                    />
                </>
               : (box.pdf) ?
                <>
                   <PdfBox
                     key={`pdfBox-${classIdRef}-${index}-${box.contentBoxId}`}
                     content={box}
                     navbarMD={navbarMD}
                     classId={classIdRef}
                     view={view}
                     user={user}
                     addContentBox={addContentBox}
                     multiBox={false}
                     updateContentBox={updateContentBox}
                     navigationVisible={navigationVisible}
                     inFullscreenModal={true}
                   />
               </>
               : (box.powerPoint) ?
               <>
                   <PowerpointBox
                     key={`powerPointBox-${classIdRef}-${index}-${box.contentBoxId}`}
                     content={box}
                     navbarMD={navbarMD}
                     classId={classIdRef}
                     view={view}
                     user={user}
                     addContentBox={addContentBox}
                     multiBox={false}
                     updateContentBox={updateContentBox}
                     navigationVisible={navigationVisible}
                   />
               </>
              : (box.countdown) ?
                <>
                    <CountdownBox
                      key={`countdownBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      navbarMD={navbarMD}
                      classId={classIdRef}
                      view={view}
                      user={user}
                      addContentBox={addContentBox}
                      multiBox={false}
                      updateContentBox={updateContentBox}
                      navigationVisible={navigationVisible}
                    />
                </>
              : 
                <>
                    <ContentBox
                      key={`contentBox-${classIdRef}-${index}-${box.contentBoxId}`}
                      content={box}
                      updateContentBox={updateContentBox}
                      classId={classIdRef}
                      user={user}
                      navbarMD={navbarMD}
                      view={view}
                      multiBox={false}
                      readOnly={location.pathname.includes('/share/')}
                      singleLineFormattingToolbar={true}
                      navigationVisible={navigationVisible}
                      inFullscreenModal={true}
                    />
                </>
            }
            </ModalBody>
        </Modal>
    )
}

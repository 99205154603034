const blogs = [
    {
        id: 'why-lesson-launchpad',
        url: 'why-lesson-launchpad',
        cardImage: 'https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Flink-preview.jpg?alt=media&token=a3c46064-25a3-42f1-a69d-2b1947de45ca',
        title: 'Why Lesson Launchpad?',
        description: "Welcome to Lesson Launchpad, the ultimate presentation web app designed with teachers in mind.",
        date: "",
        show: true,
        content: (
            <>
                <div className="row">
                        <div className="col-md-12">
                            <img src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Fwhy-lesson-launchpad.png?alt=media&token=a9bba791-40c7-49c0-967b-758e14cf8351" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                    </div>
                <div>
                    <h5>
                    Welcome to Lesson Launchpad, the ultimate presentation web app designed with teachers in mind. If you're an educator looking to transform the way you deliver daily announcements, agenda updates, and lessons, you've come to the right place. Let's explore why Lesson Launchpad is your must-have tool for a more efficient and engaging classroom experience.
                    </h5>
                </div>

                <div>
                    <h3 className="article-subheadline">Streamline Your Teaching</h3>
                    <p>
                    <b>Say goodbye to the chaos of juggling various tools and resources. Lesson Launchpad offers a seamless platform to organize and present your materials.</b> Create, edit, and deliver your lessons with ease, all from one intuitive interface. Whether you're teaching in person or online, our user-friendly design ensures a hassle-free experience.
                    </p>
                </div>

                <div>
                    <h3 className="article-subheadline">Time Managment Automated</h3>
                    <p><b>With automation features, your classes can progress seamlessly throughout the day. Timers can automatically lead up to the tardy bell, notifying students of the time remaining before class starts.</b> It's like having your own teaching assistant, ensuring every minute is utilized effectively.</p>
                </div>

                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://firebasestorage.googleapis.com/v0/b/lesson-launchpad.appspot.com/o/resources%2Fmisc%2Flink-preview.jpg?alt=media&token=a3c46064-25a3-42f1-a69d-2b1947de45ca" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you. <em>Busy between classes and don't have time to change to the next classes material?</em> <b>Lesson Launchpad will do it for you!</b> <em>Need to remind the soccer players to leave at 1:20 for their game?</em> <b>Lesson Launchpad will do it for you!</b> <em>Constantly forgetting student's birthdays?</em> <b>Lesson Launchpad will do it for you!</b> <em>Want to automatically launch a YouTube video at a particular time?</em> <b>Lesson Launchpad will do it for you!</b></p>
                        </div>
                    </div>
                </div>

                <div className="container my-4">
                    <div className="row">
                        <div>
                            <h3 className="article-subheadline">Tailored to Educators</h3>
                            <p>
                            <b>Lesson Launchpad is not just another generic presentation tool; it's designed specifically for educators.</b> We understand the unique challenges teachers face, and our platform is tailored to address them. Lesson Launchpad was built by teachers, for teachers.
                            </p>
                        </div>
                    </div>
                </div>

                <div>
                    <h3 className="article-subheadline">Support When You Need It</h3>
                    <p>
                    <b>Our commitment to your success is ongoing</b> We want to take this journey with you and are committed to making sure we build the perfect app for you and your needs.
                    </p>
                </div>
                <br></br>
                <div>
                    <p>
                    <b>In conclusion,</b> Lesson Launchpad is more than just a presentation app – it's a game-changer for educators. Streamline your teaching, engage your students like never before, and simplify your daily routine with our powerful platform. Join all of the teachers who have already embraced Lesson Launchpad and experience the difference for yourself.
                    </p>
                </div>
                <br></br>
                <div>
                    <h5>
                    Ready to take your teaching to the next level? <a href={'/auth/register'}>Sign up for Lesson Launchpad</a> today and embark on a journey of educational excellence, where automation enhances your teaching efficiency.
                    </h5>
                </div>
            </>
        ),
    },
    {
        id: 'content-boxes',
        url: 'content-boxes',
        title: 'The Content Boxes',
        date: "",
        true: false,
        content: (
            <>
                <div>
                    <h5>Content Boxes are the heart and soul of Lesson Launchpad.</h5>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Text-Editor Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Agenda Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Google Slides Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Canva Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Web Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>PDF Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Countdown Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Multi Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
                <div className="container my-4">
                    <div className="row">
                        <div className="col-md-5">
                            <img src="https://picsum.photos/350/200" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                        </div>
                        <div className="col-md-7">
                            <div>
                                <h3 style={{marginBottom: '0px'}}>Google Doc Box</h3>
                            </div>
                            <p><b>Teaching is all about timing, and Lesson Launchpad understands that.</b> Our built-in timers and stopwatches empower you to keep your lessons on track. No more fumbling with separate gadgets or checking the clock constantly – Lesson Launchpad does the job for you.</p>
                        </div>
                    </div>
                </div>
            </>
        ),
    },
    {
        id: 'automation',
        url: 'automation',
        title: 'Your Class Automated',
        date: "",
        true: false,
        content: (
            <div>
                <p>This is the content for Blog 2, and it includes some fancy elements.</p>
                <button className="btn btn-primary">Click Me</button>
            </div>
        ),
    },
    {
        id: 'tools-widgets',
        url: 'tools-widgets',
        title: 'Tools and Widgets',
        date: "",
        content: (
            <div>
                <p>Rich content for Blog 3 goes here.</p>
                <ul className="list-group">
                    <li className="list-group-item">Item 1</li>
                    <li className="list-group-item">Item 2</li>
                    <li className="list-group-item">Item 3</li>
                </ul>
            </div>
        ),
    },
    {
        id: 'blog4',
        url: 'blog4',
        title: 'Blog 4',
        date: "",
        true: false,
        content: (
            <div className="container my-4">
                <div className="row">
                    <div className="col-md-6">
                        <img src="https://picsum.photos/500/300" alt="Placeholder for Blog 4" className="img-fluid rounded" />
                    </div>
                    <div className="col-md-6">
                        <h2 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '4rem', textShadow: '1px 1px 0px #5271FF', marginTop: '3%' }}>Blog 4</h2>
                        <p>This blog features an image on the left and text on the right.</p>
                        <button className="btn btn-info">Read More</button>
                    </div>
                </div>
            </div>
        ),
    },
    {
        id: 'blog5',
        url: 'blog5',
        title: 'Blog 5',
        date: "",
        true: false,
        content: (
            <div className="container my-4">
                <div className="row">
                    <div className="col-md-6 order-md-2">
                        <img
                            src="https://picsum.photos/500/300"
                            alt="Placeholder for Blog 5"
                            className="img-fluid rounded"
                        />
                    </div>
                    <div className="col-md-6 order-md-1">
                        <h2 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '4rem', textShadow: '1px 1px 0px #5271FF', marginTop: '3%'}}>Blog 5</h2>
                        <p>
                            This blog reverses the layout with the image on the right and text 
                            on the left. It's ideal for showcasing a visual element after an introduction.
                        </p>
                        <ul className="list-group">
                            <li className="list-group-item">Engaging Content</li>
                            <li className="list-group-item">Professional Layout</li>
                            <li className="list-group-item">Responsive Design</li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    },
    {
        id: 'blog6',
        url: 'blog6',
        title: 'Blog 6',
        date: "",
        true: false,
        content: (
            <div className="container my-4">
                <div className="row align-items-center">
                    <div className="col-md-4">
                        <img
                            src="https://picsum.photos/500/300"
                            alt="Placeholder for Blog 6"
                            className="img-fluid rounded-circle"
                        />
                    </div>
                    <div className="col-md-8">
                        <h2 style={{ color: 'rgb(11, 192, 223)', fontWeight: '1000', fontSize: '4rem', textShadow: '1px 1px 0px #5271FF', marginTop: '3%'}}>Blog 6</h2>
                        <p>
                            This layout uses a smaller, circular image on the left with a larger 
                            text block on the right. It’s great for author bios, interviews, or content 
                            introductions with a personal touch.
                        </p>
                        <blockquote className="blockquote">
                            <p className="mb-0">
                                "Content is king, but context is God."
                            </p>
                            <footer className="blockquote-footer">Gary Vaynerchuk</footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        )
    }
];

export default blogs
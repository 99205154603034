import React, { useState, useRef, useEffect, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Image from '@tiptap/extension-image';
import ListItem from '@tiptap/extension-list-item';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import Heading from '@tiptap/extension-heading';
import Table from '@tiptap/extension-table';
import TableRow from '@tiptap/extension-table-row';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Italic from '@tiptap/extension-italic';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import TaskItem from '@tiptap/extension-task-item';
import TaskList from '@tiptap/extension-task-list';
import Highlight from '@tiptap/extension-highlight';
import FontSize from './FontSize';
import FontStyle from './FontStyle';
import LineHeight from './LineHeight';
import FontStylePicker from './FontStylePicker';
import FontSizePicker from './FontSizePicker';
import LineHeightPicker from './LineHeightPicker';
import FontAlignPicker from './FontAlignPicker';
import { ListItemMarker } from './ListItemMarker';
import '/Users/michaeltucker/Documents/coding-projects/client/src/assets/css/list-item-marker.css'; // Import your CSS file here
import ImageResize from 'tiptap-extension-resize-image';
import HorizontalRule from '@tiptap/extension-horizontal-rule';
import FilePicker from './FilePicker';
import { useLocation } from "react-router-dom";
import Youtube from '@tiptap/extension-youtube';
import ResizableYouTubeNode from './ResizableYouTubeNode';
import ColorPicker from './ColorPicker';
import TableOptionsPicker from './TableOptionsPicker';
import Link from '@tiptap/extension-link'
import Emoji, { gitHubEmojis } from '@tiptap-pro/extension-emoji'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, Input, Row, Col, Button } from 'reactstrap';
import emojiIcon from '/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/face-smile-regular.svg';

const FloatingToolbar = ({
  editor,
  selectedFontStyle,
  selectedFontSize,
  selectedLineHeight,
  selectedAlign,
  handleFontStyleChange,
  handleFontSizeChange,
  handleLineHeightChange,
  handleFontAlignChange,
  toggleFilePickerModal,
  position,
  setLink,
  toggleImageURLModal,
  toggleEmojiPicker,
  singleLineFormattingToolbar
}) => {
  const location = useLocation();
  const [colorPickerVisible, setColorPickerVisible] = useState(false);
  const [imageDropdownOpen, setImageDropdownOpen] = useState(false);

  const toggleImageDropdown = () => setImageDropdownOpen((prevState) => !prevState);

  const toolbarRef = useRef(null);

  const toggleColorPicker = () => {
    setColorPickerVisible(!colorPickerVisible);
  };

  const getEmbedUrl = (url) => {
    const videoId = url.split('v=')[1]?.split('&')[0];
    return videoId ? `https://www.youtube.com/embed/${videoId}` : url;
  };

  const addYouTubeVideo = () => {
    const url = prompt('Enter YouTube URL');
    if (url) {
      const embedURL = getEmbedUrl(url);
      editor.chain().focus().insertContent({
        type: 'resizableYouTube',
        attrs: {
          src: embedURL,
          width: 400,
          height: 300,
        },
      }).run();
    }
  };

  // Prevent toolbar from disappearing when clicking inside it
  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (toolbarRef.current) {
      toolbarRef.current.addEventListener('mousedown', handleMouseDown);
    }

    return () => {
      if (toolbarRef.current) {
        toolbarRef.current.removeEventListener('mousedown', handleMouseDown);
      }
    };
  }, []);

  const [isListDisabled, setIsListDisabled] = useState(false);

  const isInTable = (editor) => {
    const state = editor.state;
    const { $from } = state.selection;
    
    // Check if the current selection is inside a table, table row, table cell, or table header
    const nodeType = $from.node(-1)?.type.name;
    return (
      nodeType === 'table' ||
      nodeType === 'tableRow' ||
      nodeType === 'tableCell' ||
      nodeType === 'tableHeader' // Include table header in the check
    );
  };

  useEffect(() => {
    if (!editor) return;
  
    const updateDisabledState = () => {
      const isInsideTable = isInTable(editor);
      setIsListDisabled(isInsideTable);
    };
  
    // Call the update function on every selection update
    editor.on('selectionUpdate', updateDisabledState);
  
    // Cleanup the listener on unmount
    return () => {
      editor.off('selectionUpdate', updateDisabledState);
    };
  }, [editor]);

  const [toolbarGroup, setToolbarGroup] = useState(1)

  function scrollRight() {
    setToolbarGroup((prev) => (prev < 3 ? prev + 1 : prev));
  }
  
  function scrollLeft() {
    setToolbarGroup((prev) => (prev > 1 ? prev - 1 : prev));
  }
  
  if (location.pathname === '/print') {
    return (
      <div className="floating-toolbar" ref={toolbarRef} style={{ position: 'absolute', top: -75 }}>
        <div className="toolbar-row">
          <button onClick={() => editor?.chain().focus().undo().run()}><i className="fa fa-rotate-left"></i></button>
          <button onClick={() => editor?.chain().focus().redo().run()}><i className="fa fa-rotate-right"></i></button>
          <div style={{ width: '125px' }}>
            <FontStylePicker selectedFont={selectedFontStyle} onChangeFunction={handleFontStyleChange} fromEditorToolbar={true} />
          </div>
          <div style={{ width: '75px' }}>
            <FontSizePicker selectedSize={selectedFontSize} onChangeFunction={handleFontSizeChange} fromEditorToolbar={true} />
          </div>
          <div className="toolbar-spacer"></div>
          <button onClick={() => editor?.chain().focus().toggleBold().run()} className={editor?.isActive('bold') ? 'is-active' : ''}><i className="fa fa-bold"></i></button>
          <button onClick={() => editor?.chain().focus().toggleItalic().run()} className={editor?.isActive('italic') ? 'is-active' : ''}><i className="fa fa-italic"></i></button>
          <button onClick={() => editor?.chain().focus().toggleUnderline().run()} className={editor?.isActive('underline') ? 'is-active' : ''}><i className="fa fa-underline"></i></button>
        </div>
        <div className="toolbar-row">
          <div style={{ width: '50px' }}>
            <FontAlignPicker selectedAlign={selectedAlign} onChangeFunction={handleFontAlignChange} fromEditorToolbar={true} />
          </div>
          <div style={{ width: '50px' }}>
            <LineHeightPicker selectedLineHeight={selectedLineHeight} onChangeFunction={handleLineHeightChange} />
          </div>
          <div className="toolbar-spacer"></div>
          <button 
            onClick={() => { 
                  editor.chain().focus().toggleBulletList().run(); 
                  editor.chain().focus().setFontSize(selectedFontSize).run(); 
            }} 
            className={editor?.isActive('bulletList') ? 'is-active' : ''}
            disabled={isListDisabled}
          >
            <i className="fa fa-list-ul"></i>
          </button>
          <button 
            onClick={() => { 
                  editor.chain().focus().toggleOrderedList().run(); 
                  editor.chain().focus().setFontSize(selectedFontSize).run(); 
            }}
            className={editor?.isActive('orderedList') ? 'is-active' : ''}
            disabled={isListDisabled}
          >
            <i className="fa fa-list-ol"></i>
          </button>
          <button 
            onClick={() => editor?.chain().focus().toggleTaskList().run()} 
            className={editor?.isActive('taskList') ? 'is-active' : ''}
            disabled={isListDisabled}
          >
            <i className="fa fa-check"></i>
          </button>
          <div className="toolbar-spacer"></div>
          <div style={{ width: '50px' }}>
            <TableOptionsPicker editor={editor} />
          </div>          
          <button onClick={toggleFilePickerModal}><i className="fa fa-image"></i></button>
          <button onClick={addYouTubeVideo}><i className="fa fa-youtube"></i></button>
          <button onClick={setLink} className={editor && editor.isActive('link') ? 'is-active' : ''}><i className="fa fa-link"></i></button>
          <button onClick={toggleEmojiPicker} style={{ position: 'relative', padding: '4px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src={emojiIcon}
              alt="emoji-icon"
              style={{ height: '12px' }}
            />
          </button>
          <button onClick={() => editor.chain().focus().setHorizontalRule().run()}><i className="fa fa-minus"></i></button>
        </div>
      </div>
    );
  } else if (location.pathname === '/announcement-form') {
    return (
      <div className="floating-toolbar" ref={toolbarRef} style={{ position: 'absolute', top: -39 }}>
        <div className="toolbar-row">
          <div style={{ width: '125px' }}>
            <FontStylePicker selectedFont={selectedFontStyle} onChangeFunction={handleFontStyleChange} fromEditorToolbar={true} />
          </div>
          <div className="toolbar-spacer"></div>
          <button onClick={() => editor?.chain().focus().toggleBold().run()} className={editor?.isActive('bold') ? 'is-active' : ''}><i className="fa fa-bold"></i></button>
          <button onClick={() => editor?.chain().focus().toggleItalic().run()} className={editor?.isActive('italic') ? 'is-active' : ''}><i className="fa fa-italic"></i></button>
          <button onClick={() => editor?.chain().focus().toggleUnderline().run()} className={editor?.isActive('underline') ? 'is-active' : ''}><i className="fa fa-underline"></i></button>
          <ColorPicker editor={editor} visible={colorPickerVisible} toggleVisible={toggleColorPicker} />
          <button onClick={toggleEmojiPicker} style={{ position: 'relative', padding: '4px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src={emojiIcon}
              alt="emoji-icon"
              style={{ height: '12px' }}
            />
          </button>
        </div>
      </div>
    );
  } else if (singleLineFormattingToolbar) {
    return ReactDOM.createPortal(
      <div className="floating-toolbar-single-line" ref={toolbarRef} style={{ top: position.top, left: position.left }}>
        <button className="toolbar-single-line-scroll-button" onClick={scrollLeft}><i className='fa fa-caret-left'></i></button>
          {toolbarGroup === 1 ? 
          <div className="toolbar-row-single-line">
            <button onClick={() => editor?.chain().focus().undo().run()}><i className="fa fa-rotate-left"></i></button>
            <button onClick={() => editor?.chain().focus().redo().run()}><i className="fa fa-rotate-right"></i></button>
            <div style={{ width: '125px' }}>
              <FontStylePicker selectedFont={selectedFontStyle} onChangeFunction={handleFontStyleChange} fromEditorToolbar={true} />
            </div>
            <div style={{ width: '75px' }}>
              <FontSizePicker selectedSize={selectedFontSize} onChangeFunction={handleFontSizeChange} fromEditorToolbar={true} />
            </div>
            <button onClick={() => editor?.chain().focus().toggleBold().run()} className={editor?.isActive('bold') ? 'is-active' : ''}><i className="fa fa-bold"></i></button>
            <button onClick={() => editor?.chain().focus().toggleItalic().run()} className={editor?.isActive('italic') ? 'is-active' : ''}><i className="fa fa-italic"></i></button>
            <button onClick={() => editor?.chain().focus().toggleUnderline().run()} className={editor?.isActive('underline') ? 'is-active' : ''}><i className="fa fa-underline"></i></button>
          </div>
          : toolbarGroup === 2 ?
          <div className="toolbar-row-single-line">
            <ColorPicker editor={editor} visible={colorPickerVisible} toggleVisible={toggleColorPicker} />

            <div style={{ width: '50px' }}>
              <FontAlignPicker selectedAlign={selectedAlign} onChangeFunction={handleFontAlignChange} fromEditorToolbar={true} />
            </div>
            <div style={{ width: '50px' }}>
              <LineHeightPicker selectedLineHeight={selectedLineHeight} onChangeFunction={handleLineHeightChange} />
            </div>
            <button disabled={isListDisabled} onClick={() => editor?.chain().focus().toggleBulletList().run()} className={editor?.isActive('bulletList') ? 'is-active' : ''}>
              <i className="fa fa-list-ul"></i>
            </button>
            <button disabled={isListDisabled} onClick={() => editor?.chain().focus().toggleOrderedList().run()} className={editor?.isActive('orderedList') ? 'is-active' : ''}>
              <i className="fa fa-list-ol"></i>
            </button>
            <button disabled={isListDisabled} onClick={() => editor?.chain().focus().toggleTaskList().run()} className={editor?.isActive('taskList') ? 'is-active' : ''}><i className="fa fa-check"></i></button>
            <div style={{ width: '50px' }}>
              <TableOptionsPicker editor={editor} />
            </div>
            <Dropdown className="line-height-picker" direction='down' isOpen={imageDropdownOpen} toggle={toggleImageDropdown}>
              <DropdownToggle caret><i className="fa fa-image"></i></DropdownToggle>
              <DropdownMenu className="font-align-picker-menu">
                <DropdownItem onClick={toggleImageURLModal}><i className="fa fa-link"></i>{' '}Link</DropdownItem>
                <DropdownItem onClick={toggleFilePickerModal}><i className="fa fa-upload"></i>{' '}Upload</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
          : toolbarGroup === 3 ?
          <div className="toolbar-row-single-line">
            <button onClick={addYouTubeVideo}><i className="fa fa-youtube"></i></button>
            <button onClick={setLink} className={editor && editor.isActive('link') ? 'is-active' : ''}><i className="fa fa-link"></i></button>
            <button onClick={toggleEmojiPicker} style={{ position: 'relative', padding: '4px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <img
                src={emojiIcon}
                alt="emoji-icon"
                style={{ height: '12px' }}
              />
            </button>        
            <button onClick={() => editor.chain().focus().setHorizontalRule().run()}><i className="fa fa-minus"></i></button>
          </div>
          : null}
        <button className="toolbar-single-line-scroll-button-right" onClick={scrollRight}><i className='fa fa-caret-right'></i></button>
      </div>
      ,document.body
    );
  } else {
    return ReactDOM.createPortal(
      <div className="floating-toolbar" ref={toolbarRef} style={{ top: position.top, left: position.left }}>
        <div className="toolbar-row">
          <button onClick={() => editor?.chain().focus().undo().run()}><i className="fa fa-rotate-left"></i></button>
          <button onClick={() => editor?.chain().focus().redo().run()}><i className="fa fa-rotate-right"></i></button>
          <div style={{ width: '125px' }}>
            <FontStylePicker selectedFont={selectedFontStyle} onChangeFunction={handleFontStyleChange} fromEditorToolbar={true} />
          </div>
          <div style={{ width: '75px' }}>
            <FontSizePicker selectedSize={selectedFontSize} onChangeFunction={handleFontSizeChange} fromEditorToolbar={true} />
          </div>
          <div className="toolbar-spacer"></div>
          <button onClick={() => editor?.chain().focus().toggleBold().run()} className={editor?.isActive('bold') ? 'is-active' : ''}><i className="fa fa-bold"></i></button>
          <button onClick={() => editor?.chain().focus().toggleItalic().run()} className={editor?.isActive('italic') ? 'is-active' : ''}><i className="fa fa-italic"></i></button>
          <button onClick={() => editor?.chain().focus().toggleUnderline().run()} className={editor?.isActive('underline') ? 'is-active' : ''}><i className="fa fa-underline"></i></button>
          <ColorPicker editor={editor} visible={colorPickerVisible} toggleVisible={toggleColorPicker} />
        </div>
        <div className="toolbar-row">
          <div style={{ width: '50px' }}>
            <FontAlignPicker selectedAlign={selectedAlign} onChangeFunction={handleFontAlignChange} fromEditorToolbar={true} />
          </div>
          <div style={{ width: '50px' }}>
            <LineHeightPicker selectedLineHeight={selectedLineHeight} onChangeFunction={handleLineHeightChange} />
          </div>
          <div className="toolbar-spacer"></div>
          <button 
            onClick={() => { 
                  editor.chain().focus().toggleBulletList().run(); 
                  editor.chain().focus().setFontSize(selectedFontSize).run(); 
            }} 
            className={editor?.isActive('bulletList') ? 'is-active' : ''}
            disabled={isListDisabled}
          >
            <i className="fa fa-list-ul"></i>
          </button>
          <button 
            onClick={() => { 
                  editor.chain().focus().toggleOrderedList().run(); 
                  editor.chain().focus().setFontSize(selectedFontSize).run(); 
            }}
            className={editor?.isActive('orderedList') ? 'is-active' : ''}
            disabled={isListDisabled}
          >
            <i className="fa fa-list-ol"></i>
          </button>
          <button
            onClick={() => editor?.chain().focus().toggleTaskList().run()}
            className={isListDisabled ? 'is-disabled' : editor?.isActive('taskList') ? 'is-active' : ''}
            disabled={isListDisabled}
          >
            <i className="fa fa-check"></i>
          </button>
          <div className="toolbar-spacer"></div>
          {/* <button onClick={() => editor?.chain().focus().insertTable({ rows: 3, cols: 3 }).run()}><i className="fa fa-table"></i></button> */}
          <div style={{ width: '50px' }}>
            <TableOptionsPicker editor={editor} />
          </div>
          {/* <button onClick={toggleFilePickerModal}><i className="fa fa-image"></i></button> */}
          <Dropdown className="line-height-picker" direction='down' isOpen={imageDropdownOpen} toggle={toggleImageDropdown}>
            <DropdownToggle caret><i className="fa fa-image"></i></DropdownToggle>
            <DropdownMenu className="font-align-picker-menu">
              <DropdownItem onClick={toggleImageURLModal}><i className="fa fa-link"></i>{' '}Link</DropdownItem>
              <DropdownItem onClick={toggleFilePickerModal}><i className="fa fa-upload"></i>{' '}Upload</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <button onClick={addYouTubeVideo}><i className="fa fa-youtube"></i></button>
          <button onClick={setLink} className={editor && editor.isActive('link') ? 'is-active' : ''}><i className="fa fa-link"></i></button>
          <button onClick={toggleEmojiPicker} style={{ position: 'relative', padding: '4px 10px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <img
              src={emojiIcon}
              alt="emoji-icon"
              style={{ height: '12px' }}
            />
          </button>
          <button onClick={() => editor.chain().focus().setHorizontalRule().run()}><i className="fa fa-minus"></i></button>
        </div>
      </div>,
      document.body
    );
  }
};

const TipTapEditor = ({ setEditedContent, initialValue, handleOnBlur, readOnly, singleLineFormattingToolbar, settings }) => {
  const [selectedFontStyle, setSelectedFontStyle] = useState(settings?.defaultFontStyle || 'helvetica');
  const location = useLocation();
  const selectedFontSizeRef = useRef(location.pathname === '/announcement-form' ? '48px' : settings?.defaultFontSize);
  const [selectedLineHeight, setSelectedLineHeight] = useState('1.5');
  const [selectedAlign, setSelectedAlign] = useState('left');
  const [toolbarVisible, setToolbarVisible] = useState(false);
  const [toolbarPosition, setToolbarPosition] = useState({ top: 0, left: 0 });
  const editorRef = useRef();
  const [filePickerModal, setFilePickerModal] = useState(false);
  const [imageURLModal, setImageURLModal] = useState(false);
  const [imageURL, setImageURL] = useState("");
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);

  const toggleEmojiPicker = useCallback(() => {
    setEmojiPickerVisible((prevVisible) => !prevVisible);
  }, []);

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const handleEmojiSelect = (emoji) => {
    if (editor) {
      editor.chain().focus().setEmoji(emoji).run();
    }
    toggleEmojiPicker(); // Close the modal after emoji selection
  };

  const toggleImageURLModal = () => setImageURLModal((prevState) => !prevState);

  const handleImageURLChange = (e) => {
    setImageURL(e.target.value);
  };

  const handleInsertImageURL = () => {
    editor.chain().focus().setImage({ src: imageURL }).run();
    toggleImageURLModal()
  }

  const determineContent = (data) => {
    if (location.pathname === '/print') {
      // Make a deep copy of the object
      const copiedData = deepCopy(data);
  
      // Call the function on the copied object
      updateColorToNull(copiedData);
  
      return copiedData;
    } else {
      return initialValue || 
      {
        "type": "doc",
        "content": [
            {
                "type": "paragraph",
                "attrs": {
                    "textAlign": "left"
                },
                "content": [
                    {
                        "type": "text",
                        "marks": [
                            {
                                "type": "textStyle",
                                "attrs": {
                                    "fontSize": location.pathname === '/announcement-form' ? '48px' : settings?.defaultFontSize || "14px",
                                    "fontFamily": settings?.defaultFontStyle || "montserrat",
                                }
                            }
                        ],
                        "text": "Type here..."
                    }
                ]
            }
        ]
      };
    }
  };

  function deepCopy(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  function updateColorToNull(obj) {
    for (let key in obj) {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            updateColorToNull(obj[key]);
        } else if (key === 'color') {
            obj[key] = null;
        }
    }
  }

  const content = determineContent(initialValue);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        // paragraph: {
        //   HTMLAttributes: {
        //     style: `font-size: ${selectedFontSizeRef.current}; font-family: ${selectedFontStyle};`,
        //   }
        // }
      }),
      TextStyle,
      Color,
      ImageResize,
      Link,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TableCell,
      TableHeader,
      Underline,
      Highlight,
      TextAlign.configure({
        types: ['heading', 'paragraph'],
      }),
      TaskList,
      TaskItem.configure({
        nested: true,
      }),
      FontSize.configure({
        defaultFontSize: selectedFontSizeRef.current,
      }),
      FontStyle,
      LineHeight,
      ListItemMarker,
      Youtube.configure({
        inline: false,
        width: 400,
        height: 300,
      }),
      ResizableYouTubeNode,
      Emoji.configure({
        emojis: gitHubEmojis,
      })
    ],
    content: content,
    onFocus: ({ view }) => {
      if (!location.pathname.includes('/share/')) {
        setToolbarVisible(true);
        updateToolbarPosition(view);
      }
    },
    onBlur: ({ event }) => {
      // Check if the blur event target is outside the toolbar
      if (!event.relatedTarget || !event.relatedTarget.closest('.floating-toolbar') || !event.target.nodeName === "LI") {
        setToolbarVisible(false);
        handleOnBlur();
      }
    },
    onUpdate: ({ editor }) => {
      if (!readOnly) {
        setEditedContent(prev => ({ ...prev, content: editor.getJSON(), text: editor.getText(), html: editor.getHTML() }));
      }
    },
    onSelectionUpdate: ({ editor }) => {
      if (editor) {
        const fontSize = editor.getAttributes('textStyle').fontSize || settings?.defaultFontSize;
        const fontFamily = editor.getAttributes('textStyle').fontFamily || 'helvetica';
        if (typeof fontSize === 'object' && fontSize !== null) {
          selectedFontSizeRef.current = fontSize.fontSize;
        } else {
          selectedFontSizeRef.current = fontSize;
        }
        if (typeof fontFamily === 'object' && fontFamily !== null) {
          setSelectedFontStyle(fontFamily.fontFamily);
        } else {
          setSelectedFontStyle(fontFamily);
        }
      }
    }
  });

  // console.log("initialValue => ", initialValue)
  // console.log("editor => ", editor)

  const toggleFilePickerModal = () => {
    setFilePickerModal(!filePickerModal);
  };

  const addImageV2 = (url) => {
    if (url) {
      editor.chain().focus().setImage({ src: url }).run();
    }
    toggleFilePickerModal();
  };

  const handleFontStyleChange = (e) => {
    const font = e.target.value;
    setSelectedFontStyle(font);
    if (editor) {
      editor.chain().focus().setFontFamily(font).run();
    }
  };

  const handleFontSizeChange = (e) => {
    const size = e.target.value;
    selectedFontSizeRef.current = size;
    if (editor) {
      editor.chain().focus().setFontSize(size).run();
    }
  };

  const handleLineHeightChange = (e) => {
    const lineHeight = e.target.value;
    setSelectedLineHeight(lineHeight);
    if (editor) {
      editor.chain().focus().setLineHeight(lineHeight).run();
    }
  };

  const handleFontAlignChange = (e) => {
    const align = e.target.value;
    setSelectedAlign(align);
    if (editor) {
      editor.chain().focus().setTextAlign(align).run();
    }
  };

  const updateToolbarPosition = useCallback((view) => {
    if (view?.dom) {
      const { top, left, height } = view.dom.getBoundingClientRect();
      if (location.pathname === '/print') {
        setToolbarPosition({
          top: top,
          left: left,
        });
      } else {
        setToolbarPosition({
          top: window.scrollY + top - 75, // Adjust as needed to position the toolbar above the editor
          left: left - 15,
        });
      }
    }
  }, []);

  useEffect(() => {
    if (editor) {
      updateToolbarPosition(editor.view);
      if (readOnly) {
        editor.setEditable(false)
      }
    }
  }, [editor, updateToolbarPosition]);

  useEffect(() => {
    const handleResize = () => {
      if (editor) {
        updateToolbarPosition(editor.view);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [editor, updateToolbarPosition]);

  // window.document.addEventListener('keydown', function(event) {
  //   if (event.key === 'Tab') { // 'Tab' is the key value for the Tab key
  //       console.log("event => ", event);
  //       editor.chain().focus().sinkListItem('listItem').run()
  //       // Prevent the default action if needed
  //       // event.preventDefault();
  //   }
  // });

  useEffect(() => {
    if (editorRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (editor) {
          updateToolbarPosition(editor.view);
        }
      });
      resizeObserver.observe(editorRef.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [editor, updateToolbarPosition]);

  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);
  
    // cancelled
    if (url === null) {
      return;
    }
  
    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();
      return;
    }
  
    // ensure the URL contains "http://"
    const validatedUrl = url.startsWith('http://') || url.startsWith('https://') ? url : `http://${url}`;
  
    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: validatedUrl }).run();
  }, [editor]);
  

  useEffect(() => {
    // Initial toolbar position update
    updateToolbarPosition();

    // Add scroll event listener
    window.addEventListener('scroll', updateToolbarPosition);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', updateToolbarPosition);
    };
  }, [updateToolbarPosition]);

  return (
    <div className="editor-container" ref={editorRef} style={{ width: '100%', height: '100%' }}>
      {toolbarVisible && ( //toolbarVisible
        <FloatingToolbar
          editor={editor}
          selectedFontStyle={selectedFontStyle}
          selectedFontSize={selectedFontSizeRef.current}
          selectedLineHeight={selectedLineHeight}
          selectedAlign={selectedAlign}
          handleFontStyleChange={handleFontStyleChange}
          handleFontSizeChange={handleFontSizeChange}
          handleLineHeightChange={handleLineHeightChange}
          handleFontAlignChange={handleFontAlignChange}
          toggleFilePickerModal={toggleFilePickerModal}
          position={toolbarPosition}
          setLink={setLink}
          toggleImageURLModal={toggleImageURLModal}
          toggleEmojiPicker={toggleEmojiPicker}
          singleLineFormattingToolbar={singleLineFormattingToolbar}
        />
      )}
      {/* {contextMenuVisible && (
        <ContextMenu position={contextMenuPosition} commands={commands} hideContextMenu={hideContextMenu} />
      )} */}
      {editor && <EditorContent editor={editor} style={{ height: '100%', padding: '2px' }} />}
      <FilePicker toggleFilePickerModal={toggleFilePickerModal} fileType={'images'} isOpen={filePickerModal} onOpenClick={addImageV2} />
      <Modal toggle={toggleImageURLModal} isOpen={imageURLModal}>
        <ModalBody>
          <Row>
            <Col md="8">
              <Input
                style={{height: '40px'}}
                type="text"
                placeholder='Enter image URL...'
                onChange={(e) => handleImageURLChange(e)}
              >
              </Input>
            </Col>
            <Col md="2">
              <Button
                style={{margin: '0px', width: '100%'}}
                disabled={imageURL === ""}
                onClick={() => handleInsertImageURL()}
              >
                Insert
              </Button>
            </Col>
            <Col md="2">
              <Button
                style={{margin: '0px', width: '100%'}}
                onClick={() => toggleImageURLModal()}
              >
                Cancel
              </Button>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
      <Modal isOpen={emojiPickerVisible} toggle={toggleEmojiPicker} scrollable>
          <ModalBody>
            <div style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(16, 1fr)',
              gap: '1px',
            }}>
              {gitHubEmojis.map((emoji, index) => (
                <button
                  key={index}
                  onClick={() => handleEmojiSelect(emoji.name)}
                  onMouseOver={() => setHoveredIndex(index)}
                  onMouseOut={() => setHoveredIndex(null)}
                  style={{
                    background: hoveredIndex === index ? 'blue' : 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '20px',
                    padding: '1px',
                    border: 'none'
                  }}
                >
                  {emoji.emoji}
                </button>
              ))}
            </div>
          </ModalBody>
        </Modal>
    </div>
  );
};

export default TipTapEditor;

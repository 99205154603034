import React, { useState } from 'react';
import { Card, Row, Col, CardBody, CardFooter, Button } from "reactstrap";

const TestimonialCarousel = ({ testimonialData, imageDimensions, handleImageLoad, handleImageClick }) => {

  const isMobile = /Mobi/.test(navigator.userAgent);

  // State to keep track of the first visible card's index
  const [currentIndex, setCurrentIndex] = useState(0);
  
  // Number of cards visible at once
  const visibleCards = isMobile ? 1 : 3;
  
  const totalCards = Object.entries(testimonialData).length;

  // Function to go to the next card
  const nextCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % totalCards);
  };

  // Function to go to the previous card
  const prevCard = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + totalCards) % totalCards);
  };

  // Get the subset of testimonial data for visible cards
  const visibleTestimonialData = Object.entries(testimonialData).slice(currentIndex, currentIndex + visibleCards);

  // Handle wrapping back to the beginning if we are at the end of the list
  const wrappedTestimonialData = visibleTestimonialData.length < visibleCards
    ? visibleTestimonialData.concat(Object.entries(testimonialData).slice(0, visibleCards - visibleTestimonialData.length))
    : visibleTestimonialData
  ;

  return (
   !isMobile ? (
    <div style={{ position: 'relative', width: '100%' }}>
      {/* Left Button */}
      <i className="nc-icon nc-minimal-left"  onClick={prevCard} style={{ position: 'absolute', left: '20px', top: '50%', zIndex: 1, cursor: 'pointer', fontSize: '40px', color: 'white' }} />

      {/* Cards */}
      <Row xs="4" style={{ height: '475px', backgroundColor: "#004aad", alignItems: 'center', justifyContent: 'center' }}>
        {wrappedTestimonialData.map(([key, data]) => (
          <Card key={key} style={{ margin: '20px', width: '300px' }}>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '180px',
                overflow: 'hidden',
                margin: '10px'
            }}>
              <img
                alt="Card"
                src={data.picture}
                height={180}
                width={imageDimensions[key] || 0}
                onLoad={(event) => handleImageLoad(key, event)}
                onClick={() => handleImageClick(data.picture)}
                style={{ 
                    objectFit: 'cover',
                    cursor: 'pointer'
                }}                        
              />
            </div>
            <CardBody style={{ marginLeft: '10px', marginRight: '10px' }}>
              <i>{`"${data.testimonial}"`}</i>
            </CardBody>
            <CardFooter>
              {data.firstName} {data.lastName}.
              <br />
              {data.gradeLevel} {data.subject}, {data.state}
            </CardFooter>
          </Card>
        ))}
      </Row>

      {/* Right Button */}
      <i className="nc-icon nc-minimal-right"  onClick={nextCard} style={{ position: 'absolute', right: '20px', top: '50%', zIndex: 1, cursor: 'pointer', fontSize: '40px', color: 'white' }} />
    </div>
    ) : (
      <div style={{ position: 'relative', width: '100%' }}>
      {/* Cards */}
      <Row style={{ height: 'auto', backgroundColor: "#004aad", alignItems: 'center', justifyContent: 'center', padding: '100px 0px' }}>
        <Col md="1">
          <i 
            className="nc-icon nc-minimal-left"
            onClick={prevCard} 
            style={{
              position: 'absolute',
              left: '30px',
              top: '50%',
              zIndex: 1,
              cursor: 'pointer',
              fontSize: '150px',
              color: 'white'
            }}
          />
        </Col>
        <Col md="10">
        {wrappedTestimonialData.map(([key, data]) => (
          <Card key={key} style={{ margin: '20px', height: 'auto' }}>
            <div style={{ 
                display: 'flex', 
                justifyContent: 'center', 
                alignItems: 'center',
                height: '800px',
                overflow: 'hidden',
                margin: '10px'
            }}>
              <img
                alt="Card"
                src={data.picture}
                height={600}
                width={imageDimensions[key] || 0}
                onLoad={(event) => handleImageLoad(key, event)}
                onClick={() => handleImageClick(data.picture)}
                style={{ 
                    objectFit: 'cover',
                    cursor: 'pointer'
                }}                        
              />
            </div>
            <CardBody style={{ margin: '20px 40px', fontSize: '60px' }}>
              <i>{`"${data.testimonial}"`}</i>
            </CardBody>
            <CardFooter style={{ margin: '20px 40px', fontSize: '60px' }}>
              {data.firstName} {data.lastName}.
              <br />
              {data.gradeLevel} {data.subject}, {data.state}
            </CardFooter>
          </Card>
        ))}
        </Col>
        <Col md="1">
        <i className="nc-icon nc-minimal-right"  onClick={nextCard} style={{ position: 'absolute', right: '30px', top: '50%', zIndex: 1, cursor: 'pointer', fontSize: '150px', color: 'white' }} />
        </Col>
      </Row>
    </div>
    )
  );
};

export default TestimonialCarousel;

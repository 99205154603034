import React, { useState } from 'react';
import {
  Col,
  Row,
  Card,
  CardBody,
  Input,
  FormGroup,
  Form,
  CardFooter,
  Button
} from 'reactstrap';
import LandingPageFooter from './LandingPageFooter';
import LandingPageTopNav from './LandingPageTopNav';
import { useLocation } from "react-router-dom";

const Contact = () => {

  const location = useLocation();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const encode = (data) => {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
        .join("&");
  }

  // Netlify form handling - https://www.netlify.com/blog/2017/07/20/how-to-integrate-netlifys-form-handling-in-a-react-app/
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...formData })
    })
      .then(() => alert("Success!"))
      .catch((error) => alert(error));
  };

  const isMobile = /Mobi/.test(navigator.userAgent);

  return (
    <>
      {location.pathname != "/settings" && <LandingPageTopNav pricing={false}/>}
    { !isMobile ? (
      <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '73vh' }}>
      <Col md='12' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>      
      <Card style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', width: '89vw' }}>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
        <h2>Contact Lesson Launchpad</h2>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
        <div style={{ flex: 1 }}>
                  <li>Need help with Lesson Launchpad?</li>
                  <li>Have an idea you would like to see?</li>
                  <li>Need to report a bug?</li>
                  <br></br>
                  <p>We'd love to hear from you.</p>
                  <p>Fill out the contact form and we'll get back to you soon.</p>
                  <br></br>
                  <h3 style={{marginBottom: '5px'}}>Find us on Social Media</h3>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: '0px' }}>
                        <a href="https://www.facebook.com/lessonlaunchpad" target="_blank">
                          <Button color="facebook" style={{width: '16vw'}}>
                              <i className="fa fa-facebook-square" />
                              {' '}Share on Facebook
                          </Button>
                        </a>
                        <a href="https://www.youtube.com/@LessonLaunchpad" target="_blank">
                          <Button color="youtube" style={{width: '16vw'}}>
                              <i className="fa fa-youtube" />
                              {' '}View our YouTube
                          </Button>
                        </a>
                      </div>
              </div>
              <div style={{ flex: 1, width: '20%'}}>
                  <Card>
                  {/* <CardHeader>
                      <CardTitle tag="h4">Contact Us</CardTitle>
                  </CardHeader> */}
                  <CardBody>
                      <Form action="#" method="#" netlify={true}>
                      {/* //This is for Netlify form handling */}
                      <input type="hidden" name="form-name" value="contact" />
                      {/* //This is for Netlify form handling */}
                      <label>Name (required)</label>
                      <FormGroup>
            <Input
              placeholder="Enter name"
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Enter email"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Enter subject"
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Input
              placeholder="Enter message"
              type="textarea"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            />
          </FormGroup>
        </Form>
      </CardBody>
      <CardFooter>
        <Button
          className="btn-round"
          color="info"
          type="submit"
          onClick={handleSubmit}
        >
          Send
        </Button>
      </CardFooter>
                  </Card>
              </div>
      </div>
      </Card>
      </Col>
      </Row>
    ) : (
      <>
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '5vh'}}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2 style={{ fontSize: '90px'}}>Contact Lesson Launchpad</h2>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '0px 200px' }}>
            <div style={{ flex: 1, fontSize: '50px' }}>
                <li>Need help with Lesson Launchpad?</li>
                <li>Have an idea you would like to see?</li>
                <li>Need to report a bug?</li>
                <br></br>
                <p>We'd love to hear from you.</p>
                <p>Fill out the contact form and we'll get back to you soon.</p>
                <br></br>
            </div>
          </div>
        </Row>

        <Row style={{display: 'flex', justifyContent: 'center', zoom: '4'}}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            <Card style={{width: '20vw'}}>
              <CardBody>
                <Form action="#" method="#" netlify={true}>
                  {/* //This is for Netlify form handling */}
                  <input type="hidden" name="form-name" value="contact" />
                  {/* //This is for Netlify form handling */}
                  <label>Name (required)</label>
                  <FormGroup>
                    <Input
                      placeholder="Enter name"
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Enter email"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Enter subject"
                      type="text"
                      name="subject"
                      value={formData.subject}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Input
                      placeholder="Enter message"
                      type="textarea"
                      name="message"
                      value={formData.message}
                      onChange={handleInputChange}
                    />
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  className="btn-round"
                  color="info"
                  type="submit"
                  onClick={handleSubmit}
                >
                  Send
                </Button>
              </CardFooter>
            </Card>
          </div>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '2vh', marginBottom: '2vh'}}>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <h2 style={{ fontSize: '90px'}}>Find us on Social Media</h2>
          </div>
          <a href="https://www.facebook.com/lessonlaunchpad" target="_blank">
            <Button color="facebook" style={{width: '18vw', zoom: 4}}>
                <i className="fa fa-facebook-square" />
                {' '}Share on Facebook
            </Button>
          </a>
          <a href="https://www.youtube.com/@LessonLaunchpad" target="_blank">
            <Button color="youtube" style={{width: '18vw', zoom: 4}}>
                <i className="fa fa-youtube" />
                {' '}View our YouTube
            </Button>
          </a>
        </Row>
      </>
    )}
      {location.pathname != "/settings" && <LandingPageFooter />}
    </>
  )
}

export default Contact
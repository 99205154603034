
import React, { useState, useEffect, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import { auth, db } from "/Users/michaeltucker/Documents/coding-projects/client/src/firebase.js";
import { setDoc, doc } from "firebase/firestore";
import NotificationAlert from "react-notification-alert";
import backgroundOptionsData from "/Users/michaeltucker/Documents/coding-projects/client/src/BackgroundOptionsData.js";
import ReactBSAlert from "react-bootstrap-sweetalert";
import { saveContentBoxTemplate } from "saveTemplate";
import { useLocation } from "react-router-dom";
import { Card, CardBody, Col, CardFooter, Button, Modal, ModalBody, ModalHeader, ModalFooter, Container, Row, FormGroup, Label, Input, UncontrolledTooltip, FormFeedback} from "reactstrap";
import FontStylePicker from "./FontStylePicker";
import ReactDatetime from "react-datetime";
import FontSizePicker from "./FontSizePicker";
import { createRandomId } from "RandomId";

function CountdownBox({content, setHeading, updateContentBox, classId, user, navbarMD, view, addContentBox, multiBox, updateContentBoxWithinMultiBox, printActive, navigationVisible}) {
  const [cardHeight, setCardHeight] = useState({card: "auto", editor: "auto"}); // Initially set the height to 'auto'
  const [headingInsideContentBox, setHeadingInsideContentBox] = useState()
  const [contentPreview, setContentPreview] = useState();
  const [showMainEditButton, setShowMainEditButton] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [alert, setAlert] = useState(null);
  const location = useLocation()
  const [boxToCopyId, setBoxToCopyId] = useState();
  const [copyBoxModal, setCopyBoxModal] = useState(false);
  const [classesSelectedForContentBoxCopy, setClassesSelectedForContentBoxCopy] = useState([]);
  const date = new Date().toLocaleDateString('en-CA')
  const [editCountdownBoxModal, setEditCountdownBoxModal] = useState(false)

  useEffect(() => {
    // Function to update the card height based on window height
    const updateCardHeight = () => {
      const windowHeight = window.innerHeight;
      const desiredDistance = navbarMD?.clock === '0' && navbarMD?.marquee === '0' ? 93 : 188; // Desired distance from the bottom
      const cardHeight = windowHeight - desiredDistance;
      // setCardHeight({card: `${cardHeight}px`, editor: `${cardHeight-25}px`});
      setCardHeight({card: `${navigationVisible ? cardHeight : cardHeight+50}px`, editor: `${cardHeight-25}px`});
    };

    // Call the updateCardHeight function when the window is resized
    window.addEventListener("resize", updateCardHeight);

    // Initial call to set the card height
    updateCardHeight();

    // Remove the event listener when the component is unmounted
    return () => {
      window.removeEventListener("resize", updateCardHeight);
    };
  }, [navbarMD, navigationVisible]);

  // to stop the warning of calling setState of unmounted component
  React.useEffect(() => {
    return function cleanup() {
      var id = window.setTimeout(null, 0);
      while (id--) {
        window.clearTimeout(id);
      }
    };
  }, []);

  const saveCountdownBoxData = () => {
    if (multiBox) {
      updateContentBoxWithinMultiBox({...content, content: {...dataFromModal}}, false)
    } else {
      updateContentBox(content.contentBoxId, {...content, content: {...dataFromModal}})
    }
    toggleEditCountdownBoxModal()
  }

  const zoomContentBox = async (direction) => {
    const zoomIncrement = direction === 'up' ? 0.1 : -0.1;
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (multiBox) {
        const previousZoom = content.zoom || 1
        updateContentBoxWithinMultiBox({...content, zoom: previousZoom + zoomIncrement }, true)
      } else {
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === content.contentBoxId
        );
    
        const previousZoom = userCopy.classes[classIndex].contentBoxes[contentBoxIndex].zoom || 1;
    
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex].zoom = previousZoom + zoomIncrement;
      
        // Update the document in Firestore
        await setDoc(docRef, userCopy);
      }
  
      // Update local state to trigger re-render
      // setBoxData({
      //   ...boxData,
      //   zoom: previousZoom + zoomIncrement,
      // });
  
    } catch (error) {
      console.error('Error updating document:', error);
    }
  };

  const autoSaveContentBox = async (contentBoxId, updatedContent) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex] };
          // console.log(updatedContentBox)
  
          // Update the content box with the new data
          updatedContentBox.content = updatedContent.content;
          if (headingInsideContentBox) {
            if (headingInsideContentBox.length > 9) {
              updatedContentBox.heading = headingInsideContentBox.substring(0, 9) + "..."
            } else if (headingInsideContentBox.length === 0) {
              updatedContentBox.heading = "Type here..."
            } else {
              updatedContentBox.heading = headingInsideContentBox
            }
          }

          updatedContentBox.contentPreview = contentPreview?.substring(0, 30) + "..."

          // Create a new Date object
          var currentDate = new Date();

          // Get individual components of the date
          var year = currentDate.getFullYear();
          var month = currentDate.getMonth() + 1; // Months are zero-based, so add 1
          var day = currentDate.getDate();

          // Create a formatted string
          var formattedDate = (month < 10 ? '0' + month : month) + '/' + (day < 10 ? '0' + day : day) + '/' + year;

          updatedContentBox.lastEdited = formattedDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
          showAlertForContentBoxSave();
        }
      }
    } catch (error) {
      console.error('Error updating document:', error);
      // Check if the error message does not include the specific error you want to exclude
      if (!error.message.includes("Cannot read properties of undefined (reading 'content')")) {
        showAlertForContentBoxSaveFailure();
      }
    }
};

const [activeDatesModal, setActiveDatesModal] = useState(false)

  const toggleSetActiveDatesModal = () => {
    setActiveDatesModal(!activeDatesModal)
  }

  const [activeDates, setActiveDates] = useState({
    startDate: content.startDate === undefined ? "" : content.startDate,
    endDate: content.endDate === undefined ? "" : content.endDate
  });

  const handleActiveDateChange = (event) => {
    const { name, value } = event.target;
    setActiveDates(prevDates => ({
      ...prevDates,
      [name]: value
    }));
  };

  const saveContentBoxActiveDates = async (contentBoxId) => {
    try {
      const docRef = doc(db, "users", user.uid);
  
      const userCopy = JSON.parse(JSON.stringify(user));
  
      // Find the class index based on classId
      const classIndex = userCopy.classes.findIndex(
        (c) => c.classId === classId
      );
  
      if (classIndex !== -1) {
        // Find the content box index based on contentBoxId
        const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
          (box) => box.contentBoxId === contentBoxId
        );
  
        if (contentBoxIndex !== -1) {
          // Make a copy of the existing content box using the spread operator
          const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex]};
  
          // Update the content box with the new data
          updatedContentBox.startDate = activeDates.startDate;
          updatedContentBox.endDate = activeDates.endDate;
  
          // Update the content box in the document data
          userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;
  
          // Update the document in Firestore
          await setDoc(docRef, userCopy);
  
          // Update the local state with the updated document data
          setBoxes(userCopy.classes[classIndex].contentBoxes);
        }
        toggleSetActiveDatesModal();
      }
    } catch (error) {
      console.error('Error updating document:', error);
    }
};

const [backgroundContentBoxModal, setBackgroundContentBoxModal] = useState(false);
const [backgroundContentBoxIdToChange, setBackgroundContentBoxIdToChange] = useState('');

const toggleBackgroundContentBoxModal = (contentBoxId) => {
  setBackgroundContentBoxModal(!backgroundContentBoxModal);
  setBackgroundContentBoxIdToChange(contentBoxId);
};

const saveBackgroundContentBox = async (imageFileName) => {
  const thing = `url(${imageFileName})`
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (multiBox) {
      updateContentBoxWithinMultiBox({...content, background: thing }, true)
    } else {
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === content.contentBoxId
      );
    
      userCopy.classes[classIndex].contentBoxes[contentBoxIndex].background = thing;
    
      // Update the document in Firestore
      await setDoc(docRef, userCopy);
    }

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const sendBoxToStorage = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === content.contentBoxId
    );

    userCopy.classes[classIndex].contentBoxes[contentBoxIndex].active = false;

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

  } catch (error) {
    console.error('Error updating document:', error);
  }
}

const notificationAlert = useRef();

function showAlertForContentBoxSave() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box Saved!</b>
        </div>
      </div>
    ),
    type: 'info',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  notificationAlert.current.notificationAlert(options);
}

function showAlertForContentBoxSaveFailure() {
  var options = {};
  options = {
    place: 'tc',
    message: (
      <div>
        <div>
          <b>Content Box NOT Saved!</b>
        </div>
      </div>
    ),
    type: 'danger',
    icon: "now-ui-icons ui-1_bell-53",
    autoDismiss: 3,
    closeButton: false,
  };
  notificationAlert.current.notificationAlert(options);
}

const editBoxNameAlert = (contentBoxId) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to name this box?"
      onConfirm={(e) => updateContentBoxHeading(contentBoxId, e)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={content.heading}
    />
  );
};

const updateContentBoxHeading = async (contentBoxId, updatedHeading) => {
  hideAlert()
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    if (classIndex !== -1) {
      // Find the content box index based on contentBoxId
      const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
        (box) => box.contentBoxId === contentBoxId
      );

      if (contentBoxIndex !== -1) {
        // Make a copy of the existing content box using the spread operator
        const updatedContentBox = { ...userCopy.classes[classIndex].contentBoxes[contentBoxIndex], userHeading: true };

        // Update the content box with the new data
        if (updatedHeading.length > 9) {
          updatedContentBox.heading = updatedHeading.substring(0, 9) + "..."
        } else if (updatedHeading.length === 0) {
          updatedContentBox.heading = "Type here..."
        } else {
          updatedContentBox.heading = updatedHeading
        }

        // Update the content box in the document data
        userCopy.classes[classIndex].contentBoxes[contentBoxIndex] = updatedContentBox;

        // Update the document in Firestore
        await setDoc(docRef, userCopy); // Replace with the correct path to your document

        // Update the local state with the updated document data
        setBoxes(userCopy.classes[classIndex].contentBoxes);

        successAlert()
      }
    }
  } catch (error) {
    console.error('Error updating document:', error);
  }
};

const warningWithConfirmAndCancelMessage = (id) => {
  setAlert(
    <ReactBSAlert
      warning
      style={{ display: "block", marginTop: "-100px" }}
      title="Are you sure?"
      onConfirm={() => deleteContentBox(id)}
      onCancel={() => cancelDelete()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      confirmBtnText="Yes, delete it!"
      cancelBtnText="Cancel"
      showCancel
      btnSize=""
    >
      You will not be able to recover this content box!
    </ReactBSAlert>
  );
};

const successDelete = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Deleted!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box has been deleted.
    </ReactBSAlert>
  );
};

const cancelDelete = () => {
  setAlert(
    <ReactBSAlert
      danger
      style={{ display: "block", marginTop: "-100px" }}
      title="Cancelled"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
      Your content box is safe :)
    </ReactBSAlert>
  );
};

const deleteContentBox = async (contentBoxId) => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    const contentBoxIndex = userCopy.classes[classIndex].contentBoxes.findIndex(
      (box) => box.contentBoxId === contentBoxId
    );

    // Remove the content box at the specified index
    userCopy.classes[classIndex].contentBoxes.splice(contentBoxIndex, 1);

    // Update the document in Firestore
    await setDoc(docRef, userCopy);

    // // Update the local state with the updated document data
    // setStateContent(userCopy);

    successDelete()

    console.log('Content box deleted successfully!');
  } catch (error) {
    console.error('Error deleting content box:', error);
  }
}


const addTemplateAlert = (contentBox) => {
  setAlert(
    <ReactBSAlert
      input
      showCancel
      style={{ display: "block", marginTop: "-100px" }}
      title="What would you like to call this template?"
      onConfirm={(e) => addTemplateConfirmAlert(e, contentBox)}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      cancelBtnBsStyle="danger"
      btnSize=""
      defaultValue={contentBox.heading}
    />
  );
};

const addTemplateConfirmAlert = (e, contentBox) => {
  saveContentBoxTemplate(user, e, contentBox);
  setAlert(
    <ReactBSAlert
      style={{ display: "block", marginTop: "-100px" }}
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
      title={
        <p>
          You entered: <b>{e}</b>
        </p>
      }
    />
  );
};
  
const successAlert = () => {
  setAlert(
    <ReactBSAlert
      success
      style={{ display: "block", marginTop: "-100px" }}
      title="Saved!"
      onConfirm={() => hideAlert()}
      onCancel={() => hideAlert()}
      confirmBtnBsStyle="info"
      btnSize=""
    >
    </ReactBSAlert>
  );
};

const hideAlert = () => {
  setAlert(null);
};

const isMobile = false // /Mobi/.test(navigator.userAgent);

const today = new Date().toISOString().slice(0,10)

const toggleEditCountdownBoxModal = () => {
  setEditCountdownBoxModal(prev => !prev)
}

const [dataFromModal, setDataFromModal] = useState(
  {
    eventName: content.content?.eventName || '',
    fontStyle: content.content?.fontStyle || 'helvetica',
    fontColor: content.content?.fontColor || '',
    eventDate: content.content?.eventDate || new Date().toLocaleDateString('en-CA'),
    daysToCount: content.content?.daysToCount || ''
  }
)

const handleDataFromModalChange = (e, date = false) => {
  if (date) {
    const formattedDate = e.format('YYYY-MM-DD'); // Adjust the format as needed
    setDataFromModal((prevData) => ({
      ...prevData,
      eventDate: formattedDate,
    }));
  } else {
    setDataFromModal((prevData) => ({
      ...prevData,
      [e.target.id]: e.target.value
    }))
  }
}

// function calculateDaysRemaining(targetDate) {
//   // Parse the target date
//   const target = new Date(targetDate);
  
//   // Get the current date
//   const today = new Date();
  
//   // Calculate the difference in time
//   const diffInTime = target.getTime() - today.getTime();
  
//   // Calculate the difference in days
//   const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
  
//   return diffInDays;
// }

function calculateDaysRemaining(targetDate) {
  // Parse the target date
  const target = new Date(targetDate);
  
  // Get the current date
  const today = new Date();

  if (content.content?.daysToCount === "weekDaysOnly" || dataFromModal.daysToCount === "weekDaysOnly") {
    // Initialize a counter for weekdays
    let weekDaysCount = 0;
    
    // Loop from today to the target date
    let currentDate = new Date(today);
    
    while (currentDate <= target) {
      const dayOfWeek = currentDate.getDay(); // 0 = Sunday, 1 = Monday, ..., 6 = Saturday
      
      // If it's a weekday (Monday to Friday), increment the counter
      if (dayOfWeek !== 0 && dayOfWeek !== 6) {
        weekDaysCount++;
      }
      
      // Move to the next day
      currentDate.setDate(currentDate.getDate() + 1);
    }
    
    return weekDaysCount;
  } else {
    // Calculate the difference in time
    const diffInTime = target.getTime() - today.getTime();
    
    // Calculate the difference in days
    const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
    
    return diffInDays;
  }
}

const toggleCopyBoxModal = () => {
  setCopyBoxModal(!copyBoxModal)
  setClassesSelectedForContentBoxCopy([])
}

const handleCheckboxChange = (classId) => {
  setClassesSelectedForContentBoxCopy(prev => {
    if (prev.includes(classId)) {
      // If classId is already selected, remove it
      return prev.filter(id => id !== classId);
    } else {
      // If classId is not selected, add it
      return [...prev, classId];
    }
  });
};

const copyBoxToClasses = async () => {
  try {
    const docRef = doc(db, "users", user.uid);

    const userCopy = JSON.parse(JSON.stringify(user));

    // Find the class index based on classId
    const classIndex = userCopy.classes.findIndex(
      (c) => c.classId === classId
    );

    let boxToCopy = {};

    if (multiBox) {
      boxToCopy = {...content, heading: 'Countdown Box'}
    } else {
      boxToCopy = userCopy.classes[classIndex].contentBoxes.find(box => box.contentBoxId === boxToCopyId);
    }

    boxToCopy = { ...boxToCopy, contentBoxId: createRandomId() };

    let classesCopy = [...userCopy.classes];

    classesSelectedForContentBoxCopy.forEach(classId => {
      const classIndex = classesCopy.findIndex(c => c.classId === classId);

      if (classIndex !== -1) {
        // Copy the contentBoxes array and add the new box
        const updatedContentBoxes = [...classesCopy[classIndex].contentBoxes, boxToCopy];
        classesCopy[classIndex] = {
          ...classesCopy[classIndex],
          contentBoxes: updatedContentBoxes
        };
      } else {
        console.log(`Class with ID ${classId} not found.`);
      }
    });

    userCopy.classes = classesCopy;

    await setDoc(docRef, userCopy);

    toggleCopyBoxModal()

  } catch (error) {
    console.log("failed", error);
  }
};

  return (
    <>
    <Col 
      style={{ flex: "1", minHeight: "0", padding: '4px', height: '100%', border: showButtons ? 'rgb(11, 192, 223) solid 2px' : 'transparent solid 2px', borderRadius: '15px' }}
      onMouseEnter={() => setShowMainEditButton(true)}
      onMouseLeave={() => { setShowMainEditButton(false); setShowButtons(false)}}
    >
      {alert}
        <Card 
          key={content.contentBoxId}
          style={{ height: multiBox ? '100%' : cardHeight.card, backgroundImage: location.pathname !== '/print' ? content.background : null || '', zoom: location.pathname === '/print' ? '.6' : '1', border: printActive === true ? '4px solid blue' : location.pathname === '/print' ? 'solid 1px' : 'none'}}
        >
          {showMainEditButton && location.pathname !== '/add-edit-classes' && !location.pathname.includes('/share/') && (
            <>
              <Button
                // className="btn-round btn-icon"
                id={`options-${content.contentBoxId}`}
                type="button"
                style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(-60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '7px 11px', zIndex: '1049', overflow: 'visible'}}
                size="sm"
                onClick={() => setShowButtons(!showButtons)}
              >
                <i className={showButtons === false ? "fa fa-caret-down" : "fa fa-caret-up"} />
              </Button>
                <UncontrolledTooltip
                  delay={0}
                  target={`options-${content.contentBoxId}`}
                  placement="left"
                >
                    Options
                </UncontrolledTooltip>
                {showButtons && location.pathname !== '/add-edit-classes' && (
                  <>
                    <Button
                      // className="btn-round btn-icon"
                      id="editCountdown"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(60%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049', overflow: 'visible'}}
                      size="sm"
                      onClick={() => toggleEditCountdownBoxModal()}
                    >
                      <i className="fa fa-pencil" />
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="editCountdown"
                        placement="left"
                      >
                          Edit Countdown
                      </UncontrolledTooltip>
                    <Button
                    // className="btn-round btn-icon"
                    id="changeContentBoxBackground"
                    type="button"
                    style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(180%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 8px', zIndex: '1049'}}
                    size="sm"
                    onClick={() => toggleBackgroundContentBoxModal()}
                    >
                      <i class="nc-icon nc-palette"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="changeContentBoxBackground"
                        placement="left"
                      >
                          Change Background
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxBigger"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(300%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => zoomContentBox('up')}
                    >
                      <i class="fa fa-plus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxBigger"
                        placement="left"
                      >
                        Bigger
                      </UncontrolledTooltip>
                    <Button
                      // className="btn-round btn-icon"
                      id="agendaBoxSmaller"
                      type="button"
                      style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(420%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '6px 10px', zIndex: '1049'}}
                      size="sm"
                      onClick={() => zoomContentBox('down')}
                    >
                      <i class="fa fa-minus"></i>             
                    </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="agendaBoxSmaller"
                        placement="left"
                      >
                        Smaller
                      </UncontrolledTooltip>
                      {user.settings.useActiveDates && !multiBox ? (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="setContentBoxActiveDates"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => toggleSetActiveDatesModal()}
                        // onClick={null}
                        >
                          <i className="fa fa-calendar" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="setContentBoxActiveDates"
                            placement="left"
                          >
                              Set Active Dates
                          </UncontrolledTooltip>
                        </>
                      ) : !multiBox &&
                      (
                        <>
                        <Button
                        // className="btn-round btn-icon"
                        id="sendBoxToStorage"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: 'translateY(540%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => sendBoxToStorage()}
                        // onClick={null}
                        >
                          <i className="nc-icon nc-box" />
                        </Button>
                          <UncontrolledTooltip
                            delay={0}
                            target="sendBoxToStorage"
                            placement="left"
                          >
                              Send Box to Storage
                          </UncontrolledTooltip>
                        </>
                      )}
                      <Button
                        // className="btn-round btn-icon"
                        id="copyBox"
                        type="button"
                        style={{ backgroundColor: 'rgb(11, 192, 223) !important', color: 'white', position: 'absolute', transform: multiBox ? 'translateY(540%)' : 'translateY(660%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '5px 8px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => {setBoxToCopyId(content.contentBoxId); toggleCopyBoxModal()}}
                      >
                        <i className="fa fa-copy" />
                      </Button>
                      <UncontrolledTooltip
                        delay={0}
                        target="copyBox"
                        placement="left"
                      >
                          Duplicate Box
                      </UncontrolledTooltip>
                    {!multiBox && 
                      <>
                        <Button
                        // className="btn-round btn-icon"
                        id="deleteContentBox"
                        type="button"
                        style={{ backgroundColor: 'lightcoral', color: 'white', position: 'absolute', transform: 'translateY(780%)', right: '-12px', borderRadius: '30px', height: '30px', width: '30px', padding: '4px 9px', zIndex: '1049'}}
                        size="sm"
                        onClick={() => warningWithConfirmAndCancelMessage(content.contentBoxId)}
                        >
                          <i className="fa fa-trash" />
                        </Button>
                        <UncontrolledTooltip
                          delay={0}
                          target="deleteContentBox"
                          placement="left"
                        >
                            Delete
                        </UncontrolledTooltip>
                      </>
                    }
                  </>
                  )}
            </>
          )}
          <CardBody>
            {content.content !== null ?
              // <div style={{ minHeight: cardHeight.editor, overflowY: 'auto' }}>
                // <div style={{fontFamily: content.content.fontStyle, color: content.content.fontColor, display: 'flex', placeContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                <div style={{fontFamily: content.content.fontStyle, color: location.pathname !== '/print' ? content.content.fontColor : null, textAlign: 'center', zoom: content.zoom || '1'}}>
                        <Row style={{margin: '20px', marginBottom: '0px', display: 'flex', justifyContent: 'center'}}>
                          <h1 style={{margin: '0px', textAlign: 'center'}}>Days Until {content.content.eventName}:</h1>
                        </Row>
                        <Row style={{margin: '20px', marginBottom: '0px', display: 'flex', justifyContent: 'center'}}>
                          <h1 style={{fontSize: '100px', marginBottom: '0px'}}>{calculateDaysRemaining(content.content.eventDate)}</h1>
                        </Row>
                  </div>
              // </div>
            :
              <div style={{ height: '100%', overflowY: 'auto', alignContent: 'center', textAlign: 'center' }}>
                <Button
                  onClick={() => toggleEditCountdownBoxModal(!editCountdownBoxModal)}
                >
                  Edit Countdown
                </Button>
              </div>
            }
          </CardBody>
        </Card>
        <Modal 
            isOpen={activeDatesModal}
            toggle={toggleSetActiveDatesModal}
            scrollable
            style={{padding: '20px'}}
          >
            <ModalHeader toggle={toggleSetActiveDatesModal}>Set Active Dates</ModalHeader>
            <br></br>
            <ModalBody>
              <Container>
                <Row xs="2">
                  <FormGroup>
                    <Label for="startDate">
                      Start Date
                    </Label>
                    <Input
                        id="startDate"
                        name="startDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        // defaultValue={"2024-02-02"}
                        value={activeDates.startDate || today}
                    >
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="endDate">
                      End Date
                    </Label>
                    <Input
                        id="endDate"
                        name="endDate"
                        type="date"
                        onChange={handleActiveDateChange}
                        value={activeDates.endDate || today}
                    >
                    </Input>
                  </FormGroup>
                </Row>
                <Row>
                  <a href="https://lessonlaunchpad.com/faq#active-dates">What are Active Dates?</a>
                </Row>
              </Container>
            </ModalBody>
            <Button onClick={() => saveContentBoxActiveDates(content.contentBoxId)}>Save Active Dates</Button>
          </Modal>
          <Modal 
            isOpen={backgroundContentBoxModal}
            toggle={toggleBackgroundContentBoxModal}
            backdrop={false}
            scrollable
          >
            <ModalHeader toggle={toggleBackgroundContentBoxModal} />
            <ModalBody style={{padding: '0'}} className="agenda-body">
              <Container>
                <Row xs="6">
                  {backgroundOptionsData.map((option, index) => (
                    <Col key={index} onClick={() => saveBackgroundContentBox(option.image)} style={{ padding: '0px' }}>
                      <div className="image-container">
                        <img src={option.thumbnail} alt={`Background option ${index + 1}`} />
                      </div>
                    </Col>
                  ))}
                </Row>
              </Container>
            </ModalBody>
          </Modal>


          <Modal 
            style={{height: '650px'}}
            isOpen={editCountdownBoxModal}
            toggle={toggleEditCountdownBoxModal}
            backdrop={true}
          >
            <ModalHeader toggle={toggleEditCountdownBoxModal} />
            <ModalBody style={{padding: '0'}}>
            <div style={{margin: '20px'}}>
              <Row style={{margin: '10px'}}>
                <Col md="4">
                  <FormGroup>
                    <Label>Days remaining until _____ :</Label>
                    <Input
                      defaultValue={dataFromModal.eventName}
                      placeholder="Ex: the Concert"
                      id="eventName"
                      onChange={(e) => handleDataFromModalChange(e)}
                    ></Input>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Date of Event:</Label>
                    <ReactDatetime
                      // dateFormat={false}
                      timeFormat={false}
                      value={dataFromModal?.eventDate}
                      initialValue={dataFromModal?.eventDate}
                      id="eventDate"
                      inputProps={{
                        className: "form-control",
                        placeholder: ""
                      }}
                      onChange={(e) => handleDataFromModalChange(e, true)}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Days to Count:</Label>
                    <Input
                      type="select"
                      id="daysToCount"
                      onChange={(e) => handleDataFromModalChange(e)}
                      defaultValue={dataFromModal?.daysToCount}
                    >
                      <option value="all">All</option>
                      <option value="weekDaysOnly">Weekdays Only</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{margin: '10px'}}>
                <Col md="4">
                  <FormGroup>
                    <Label>Font Style</Label>
                    <FontStylePicker 
                      id="fontStyle"
                      onChangeFunction={handleDataFromModalChange}
                      selectedFont={dataFromModal.fontStyle}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Font Size (Coming Soon)</Label>
                    <FontSizePicker disabled={true}/>
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup>
                    <Label>Font Color</Label>
                    <Input
                      type="color"
                      placeholder=""
                      id="fontColor"
                      style={{height: '39px'}}
                      onChange={(e) => handleDataFromModalChange(e)}
                      value={dataFromModal.fontColor}
                    ></Input>
                  </FormGroup>
                </Col>
              </Row>
              <Row style={{margin: '10px'}}>
                <Col>
                  <Button
                    // className="btn-round btn-icon"
                    id="saveCountdownBoxData"
                    type="button"
                    size="sm"
                    onClick={() => saveCountdownBoxData()}
                  >
                    Save Countdown Box
                  </Button>
                </Col>
              </Row>
              <Row style={{margin: '10px'}}>
                <div style={{ height: '300px', margin: '10px', width: '100%'}}>
                  <Card style={{fontFamily: dataFromModal.fontStyle, color: dataFromModal.fontColor, border: '1px solid lightgrey', display: 'flex', placeContent: 'center', alignItems: 'center', textAlign: 'center'}}>
                      <div style={{margin: '30px', marginBottom: '0px'}}>
                        <h1 style={{margin: '0px'}}>Days Until {dataFromModal.eventName}:</h1>
                      </div>
                      <div>
                        <h1 style={{fontSize: '100px'}}>{calculateDaysRemaining(dataFromModal.eventDate)}</h1>
                      </div>
                    {/* <Row>
                      <h1>{dataFromModal.eventName}:</h1>
                    </Row> */}
                  </Card>
                </div>
              </Row>
            </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={copyBoxModal} toggle={toggleCopyBoxModal}>
            <ModalBody>
              <h5>Copy box to:</h5>
              {user && user.classes && user.classes.map(x => 
                <FormGroup check>
                  <Label check>
                  <Input
                    type="checkbox"
                    defaultChecked={classesSelectedForContentBoxCopy.length > 0 && classesSelectedForContentBoxCopy.includes(x.classId)}
                    onChange={() => handleCheckboxChange(x.classId)}
                  />                
                  <span className="form-check-sign" />
                  {x.className}
                  </Label>
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button disabled={classesSelectedForContentBoxCopy.length < 1} onClick={() => copyBoxToClasses()}>Copy</Button>
              <Button onClick={toggleCopyBoxModal}>Cancel</Button>
            </ModalFooter>
          </Modal>
        </Col>
    </>
  );
}

export default CountdownBox;

import { Card, CardBody, Row, Col, Navbar, NavbarBrand, Carousel, Container, CardImgOverlay, CardText, CardTitle,
    NavItem, UncontrolledAccordion, AccordionItem,
     AccordionBody, Button, Badge, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, CardHeader, CardFooter, CardImg } from "reactstrap";
import { NavLink } from 'react-router-dom';
import logoWhite from "/Users/michaeltucker/Documents/coding-projects/client/src/assets/img/logo-white.svg";

const LandingPageFooter = () => {

    const isMobile = /Mobi/.test(navigator.userAgent);

    return (
        !isMobile ? (
            <Row style={{ height: '100px', backgroundColor: "rgb(11, 192, 223)", alignItems: 'center', justifyContent: 'center' }}>
                <div style={{ color: 'white', width: '30vw', textAlign: 'left' }}>
                    Lesson Launchpad © 2025 - All Rights Reserved
                </div>
                <div style={{margin: '20px', color: 'white', width: '8vw', textAlign: 'center'}}>
                    <NavLink to="/privacy" style={{color: 'white'}}>Privacy Policy</NavLink>
                </div>
                <img
                    src={logoWhite}
                    alt="lesson-launchpad-white-logo"
                    style={{ height: '75px', width: '8vw'}}
                />
                <div style={{margin: '20px', color: 'white', width: '8vw', textAlign: 'center'}}>
                    <NavLink to="/contact" style={{color: 'white'}}>Contact</NavLink>
                </div>
                <div style={{ fontSize: '30px', color: 'white', width: '30vw', textAlign: 'right' }}>
                    <a href="https://www.facebook.com/lessonlaunchpad" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none', margin: '0px 20px', cursor: 'pointer' }}>
                        <i className="fa fa-facebook-square"></i>
                    </a>
                    <a href="https://www.youtube.com/@LessonLaunchpad" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
                        <i className="fa fa-youtube"></i>
                    </a>
                </div>
            </Row>
        ) : (
            <Row
                style={{
                    height: '600px',
                    backgroundColor: 'rgb(11, 192, 223)',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                <div 
                    style={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex'
                    }}
                >
                    <a href="https://www.facebook.com/lessonlaunchpad" target="_blank" rel="noopener noreferrer" style={{ fontSize: '', color: 'white', textDecoration: 'none', margin: '0px 20px', cursor: 'pointer' }}>
                        <i className="fa fa-facebook-square" style={{fontSize: '100px', margin: '0px 60px'}}></i>
                    </a>
                    <img
                        src={logoWhite}
                        alt="lesson-launchpad-white-logo"
                        style={{ height: '200px' }}
                    />
                    <a href="https://www.youtube.com/@LessonLaunchpad" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'none', cursor: 'pointer' }}>
                        <i className="fa fa-youtube" style={{fontSize: '100px', margin: '0px 75px'}}></i>
                    </a>
                </div>
                <div style={{ margin: '20px', color: 'white' }}>
                    <NavLink to="/contact" style={{ color: 'white' }}>
                        <h1>Contact</h1>
                    </NavLink>
                </div>
                <div style={{ margin: '20px', color: 'white' }}>
                    <NavLink to="/privacy" style={{ color: 'white' }}>
                        <h1>Privacy Policy</h1>
                    </NavLink>
                </div>
                <div style={{ margin: '20px', color: 'white' }}>
                    <h1>Lesson Launchpad © 2025 - All Rights Reserved</h1>
                </div>
            </Row>
        )
    )

}

export default LandingPageFooter
